import React, { Fragment } from 'react';
import '../styles/TransactionCard.css';

const LectureTransactionCard = (props) => {
  const { userLiveLecture, handleDownloadInvoice } = props;
  return (
    <Fragment>
{userLiveLecture?.filter(lecture => lecture.orderId !== null).map((lecture, index) => (
  <div className='TransactionCard' key={index}>
    <div className='TransactionCard-row-1'>
      <p className='first-col'>Lecture: {`${lecture?.course?.subject}`}</p>
      <p className='second-col'>
        ₹{lecture?.paidAmount !== lecture?.originalAmount ? (
          <>
            {lecture.paidAmount}{' '}
            <span
              style={{
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
                marginLeft: '10px',
                color: '#999',
              }}
            >
              ₹{lecture?.originalAmount}
            </span>
          </>
        ) : (
          lecture?.paidAmount
        )}
      </p>
      <p className='third-col'>
        {/* You can handle the date format here */}
        {lecture?.purchasedOn}
      </p>
    </div>
    <div className='TransactionCard-row-2'>
      <p className='all-chapters-text'>
        Type: {lecture?.typeofSubscription} 
      </p>
      <p
        className='download-text'
        onClick={() => handleDownloadInvoice('live', lecture,'receipt')}
      >
        Download receipt
      </p>
      <p
        className='download-text'
        onClick={() => handleDownloadInvoice('live', lecture,'invoice')}
      >
        Download invoice
      </p>
    </div>
  </div>
))}

    </Fragment>
  );
};

export default LectureTransactionCard;
