import React from 'react'
import '../styles/Footer.css';
import { useNavigate } from 'react-router-dom';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';


const Footer = () => {
    const history = useNavigate();
    return (
   <>
    <footer class="footer-area footer--light">
  <div class="footer-big">
    <div class="container">
      <div class="row">
      <div className="col-md-3 col-sm-12 ft-1">
                            <h3><span>Enrouting </span>Careers</h3>
                            <p>Enrouting Careers helps us to develop skills & interest at an early age of learning in students & by conducting events to find the best of their talents..</p>
                        
                            <div className="social-media-links">
                            <a href="" target="_blank" rel="noopener noreferrer">
        <Facebook />
      </a>
      <a href="https://www.instagram.com/enrouting_careers/" target="_blank" rel="noopener noreferrer">
        <Instagram />
      </a>
      <a href="https://www.linkedin.com/company/enrouting-careers-pvt-ltd" target="_blank" rel="noopener noreferrer">
        <LinkedIn />
      </a>
      <a href="" target="_blank" rel="noopener noreferrer">
        <Twitter />
      </a>
    </div> 
   </div>

     <div class="col-md-3 col-sm-4">
          <div class="footer-widget">
            <div class="footer-menu footer-menu--1">
              <h4 class="footer-widget-title">Explore Us</h4>
              
              <li>
              <p className='links-text' onClick={() => history('/about')}>
            About us</p>
             </li>
          <li>
          <p className='links-text' onClick={() => history('/story')}>
            Our story</p>
            </li>
            <li>
          <p className='links-text' onClick={() => history('/vision')}>
            Vision  </p>
            </li>  
             
              </div>
            </div>
        </div>

        <div class="col-md-3 col-sm-4">
          <div class="footer-widget">
          <div class="footer-menu">
              <h4 class="footer-widget-title">Support Us</h4> 

              
              <li>
                <p className='links-text' onClick={() => history('/privacy-policy')}> Privacy Policy</p>
              </li>

             <li>
                <p className='links-text' onClick={() => history('/t&c')}> Terms & Conditions</p>
              </li>  
            
              <li>
                <p className='links-text' onClick={() => history('/Help')}> Help</p>
              </li> 
           
          </div>
        </div>      
   </div>

   <div class="col-md-3 col-sm-4">
          <div class="footer-widget">
            <div class="footer-menu no-padding">
              <h4 class="footer-widget-title">Contact Us</h4>
               <p><i class="fa-solid fa-phone-volume"></i> +92 3121324083</p>
               <p><i class="fa-solid fa-map-marker-alt"></i> nanded,india </p> 
               </div>
                </div>
                </div>
                </div>
                </div>
                </div>

       
 <div class="mini-footer">
      <div class="row">
        <div class="col-md-12">
          <div class="copyright-text">
            <p>&copy; 2021 Enrouting Careers. All rights reserved 
            </p>
          </div>
      </div>
    </div>
  </div>

 </footer>
        
   </>

    )
}
export default Footer