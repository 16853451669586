import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { Container, Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllExams } from "../../redux/actions/getStudentActions";
import Card1 from "./Cards/Card1";
import Card2 from "./Cards/Card2";
import ParentLiveCards from "./Cards/ParentLiveCards";
import SubjectiveCard from "./Cards/SubjectiveCard";
import MegaExamCard from "./Cards/MegaExamCard";


import { setTeacherDetails } from "../../redux/actions/teacherActions";
import { setParentsDetails } from "../../redux/actions/parentActions";




export const Dashboard = () => {
  const dispatch = useDispatch();
  const { selectedStudent } = useSelector((store) => store.studentReducer);

  useEffect(() => {
    dispatch(fetchAllExams());
  }, [dispatch]);

  return (
    <Box
      sx={{
        backgroundColor: "#f1f5f9",
        minHeight: "100vh",
        width: "100%", // Ensure full-width background
        overflowX: "hidden", // Prevent horizontal overflow
      }}
    >
      <Navbar />
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: "80px",
          paddingLeft: { xs: 2, sm: 3 }, // Add padding on the left for mobile view
          paddingRight: { xs: 2, sm: 3 }, // Add padding on the right for mobile view
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {/* <Card2 /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <ParentLiveCards />
            <SubjectiveCard />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card1 />
            <MegaExamCard />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;