import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./components/HomePage";
import CoursePage from "./components/CoursePage";
import WorkshopPage from "./components/WorkshopPage";
import EventsPage from "./components/EventsPage";
import ProfilePage from "./components/ProfilePage";
import EditProfilePage from "./components/EditProfilePage";
import SubscriptionPage from "./components/SubscriptionPage";
import PurchasesPage from "./components/PurchasesPage";
import BlogsPage from "./components/BlogsPage";
import AboutUsPage from "./components/AboutUsPage";
import HelpPage from "./components/HelpPage";
import MyEventsPage from "./components/MyEventsPage";
import MyWorkshopPage from "./components/MyWorkshopsPage";
import ErrorPage from "./components/ErrorPage";
import ResetPasswordPage from "./components/ResetPasswordPage";
import OurVisionPage from "./components/OurVisionPage";
import OurStoryPage from "./components/OurStoryPage";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import PrivacyPolicyPage from "./components/PrivacyPolicy";
import TeacherHome from "./components/TeacherHome";
import Room from "./components/Teacher/Room";
import Live from "./components/Live";
import "./index.css";
import Lobby from "./components/Lobby";
import LivePlans from "./components/LivePlans";
import RequestLectures from "./components/RequestLectures";
import TestRoutes from "./routes/Teacher/AllTest/TestRoutes";
import StudentTestRoutes from "./routes/Student/AllTest/StudentTestRoutes";
import TestScreen from "./pages/Students/ViewTest/MCQ_TYPE/TestScreen";
import ExamDashboard from "./pages/Students/ViewTest/ExamDashboard/ExamDashboard";
import ParentRoutes from "./routes/Parents/ParentRoutes";
import ProgressPage from "./components/ProgressPage";
import MyProgress from "./components/MyProgress";
import Rewards from "./components/Rewards";
import Setting from "./components/Teacher/Setting";
import Project from "./components/Project";
import ProjectPage from "./components/Project";
import ProjectsPage from "./components/ProjectsPage";
import BulkUserPage from "./components/BulkUserPage";
import useCustomLocation from "./components/customLocation";
import Receipt from "./components/Receipt";
import Invoice from "./components/Invoice";

const App = () => {
  const location = useLocation();
  console.log('location', location)

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    document.querySelector("html").style.overflow = "auto";

    // window.scroll({ top: 0 });
    // document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes location={location}>
        <Route path="/" element={<HomePage />} />
        <Route path="/course" element={<CoursePage />} />
        <Route path="/workshop" element={<WorkshopPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/progress" element={<ProgressPage />} />
        <Route path="/profile/edit" element={<EditProfilePage />} />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/purchases" element={<PurchasesPage />} />
        <Route path="/notes" element={<PurchasesPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/vision" element={<OurVisionPage />} />
        <Route path="/story" element={<OurStoryPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/Project" element={<Project />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/t&c" element={<TermsAndConditionsPage />} />
        <Route path="/myEvents" element={<MyEventsPage />} />
        <Route path="/myWorkshops" element={<MyWorkshopPage />} />
        <Route path="/forgot-password/:token" element={<ResetPasswordPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/myProgress" element={<MyProgress />} />
        <Route path="/myRewards" element={<Rewards />} />
        <Route path="/live" element={<Live />} />
        <Route path="/exams" element={<ExamDashboard />} />
        <Route path="/live/lobby/:id" element={<Lobby />} />
        <Route path="/price" element={<LivePlans />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/teacher-home" element={<TeacherHome />} />
        <Route path="/teacher-home/room/:roomd" element={<Room />} />
        <Route path="/live-requests" element={<RequestLectures />} />
        <Route path="/teacher-projects" element={<ProjectsPage />} />
        <Route path="/teacher-users" element={<BulkUserPage />} />
        <Route path="/test/*" element={<TestRoutes />} />
        <Route path="/student-test/*" element={<StudentTestRoutes />} />
        <Route path="/parents/*" element={<ParentRoutes />} />
        <Route path='/invoice' element={<Invoice/>} />

      
      </Routes>
    </>
  );
};

export default App;
