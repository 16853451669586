import React, { useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { userSubjectiveCol } from "../../../../data/mockData";
import AddExamModal from "../../../../components/addExamModal/AddExamModal";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const UserSubjectiveTable = ({ data }) => {
  // Fetch user by token
  const tokenString = Cookies.get("userJWT");
  let token;
  if (tokenString) {
    try {
      token = jwtDecode(tokenString);
    } catch (error) {
      console.error("Invalid token", error);
    }
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = data?.filter((item) =>
    item.select_subject.toLowerCase().startsWith(searchQuery.toLowerCase())
  );
  const paginatedData = filteredData?.slice(startIndex, endIndex);

  const totalLength = filteredData && filteredData?.length;

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState(null);
  const [examData, setExamData] = useState([]);

  const [isResultOpen, setIsResultOpen] = useState(false);
  const [selectedResultData, setSelectedResultData] = useState(null);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const examIds = data.map((item) => item.id);
        const examDataPromises = examIds.map((id) =>
          axios.get(
            `https://api.enroutingcareers.com/api/userAttendExam/${id}/attended-users`
          )
        );
        const examDataResponses = await Promise.all(examDataPromises);
        const examsData = examDataResponses.map(
          (response) => response.data.data
        );
        setExamData(examsData.flat());
      } catch (error) {
        console.error("Failed to fetch exam data:", error);
      }
    };

    fetchExamData();
  }, [data]);

  //for edit modal open and closed
  const handleExamData = async (item) => {
    setSelectedFormData(item);
    console.log("itemFun", item);
    setIsEditOpen(true);
  };

  const handleCheckResult = async (item) => {
    setSelectedResultData(item);
    console.log("subResult", item);
    setIsResultOpen(true);
  };

  const handleMarksUpdate = (examId, attendedBy) => {
    console.log("examIds", examId);
    setExamData((prevData) =>
      prevData.map((exam) => {
        const currentExamId = parseInt(exam.examId);
        const targetExamId = parseInt(examId);
        console.log("exam.examId", currentExamId);
        console.log("check", currentExamId == targetExamId);
        return currentExamId == targetExamId
          ? { ...exam, attendedBy: attendedBy }
          : exam;
      })
    );
  };

  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {userSubjectiveCol?.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                    textAlign: "center",
                    borderColor: "black", // Set border color
                    borderWidth: 1, // Set border width
                    borderStyle: "solid", // Set border style
                  }}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((item) => {
              const userExamData = examData?.find(
                (exam) => exam?.examId === item?.id && exam?.userId == token?.id
              );
              return (
                <TableRow key={item.id}>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item.examName}
                  </TableCell>

                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item.select_subject}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item.select_chapter}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item.total_marks}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item.select_date}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item.start_time}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {item.end_time}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    <a
                      href={item?.questionPaperPath}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      View Questions
                    </a>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      !userExamData?.attendedBy && handleExamData(item)
                    }
                  >
                    <Button
                      size="small"
                      variant="contained"
                      className="common-color"
                      disabled={userExamData && !userExamData?.attendedBy}
                      sx={{
                        opacity: userExamData?.attendedBy ? 0.5 : 1,
                      }}
                    >
                      {userExamData?.attendedBy ? "Uploaded" : "Upload Answer"}
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: "black",
                      borderWidth: 1,
                      borderStyle: "solid",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      userExamData?.obtainedMarks &&
                      handleCheckResult(userExamData)
                    }
                  >
                    <Button
                      size="small"
                      variant="contained"
                      className="common-color"
                      disabled={userExamData && userExamData?.obtainedMarks}
                      sx={{
                        opacity: !userExamData?.obtainedMarks ? 0.5 : 1,
                      }}
                    >
                      {userExamData?.attendedBy ? "Check Result" : "Not Check"}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          width: "100%",
          padding: "0px !important",
          margin: { lg: "0", md: "0", sm: "0", xs: "10px 0px" },
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography sx={{ pl: 1 }}>Total exams : {totalLength}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              alignItems: "baseline",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          />
        </Box>
      </Box>
      {isEditOpen && (
        <AddExamModal
          formData={selectedFormData}
          open={isEditOpen}
          formName="Upload Subjective Test"
          onClose={() => setIsEditOpen(false)}
          setIsEditOpen={setIsEditOpen}
          onMarksUpdate={handleMarksUpdate}
        />
      )}

      {isResultOpen && (
        <AddExamModal
          formData={selectedResultData}
          open={isResultOpen}
          formName="Subject_Wise Result"
          onClose={() => setIsResultOpen(false)}
          setIsEditOpen={setIsResultOpen}
          onMarksUpdate={handleMarksUpdate}
        />
      )}
    </>
  );
};

export default UserSubjectiveTable;
