import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { screenSize } from "../util/screenSize";
import "../styles/ProfilePage.css";
import { getUserPoints } from "../redux/actions/userActions";
import moment from "moment";

const Rewards = () => {
  const { userPoints, userData } = useSelector((store) => store.userReducer);

  // console.log("userPoints", userPoints);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserPoints());
  }, []);

  var screen = window.innerWidth;
  const TOTAL_POINTS= (userPoints?.data?.welcomeBonus + userPoints?.data?.loyaltyPoints) 

  return (
    <div className="ProfilePage">
      <Navbar activeMenuItem={"profile"} />
      {screen > screenSize ? (
        <div className="ProfilePage-title-container">
          <div className="ProfilePage-title-row">
            <h1>My Rewards</h1>
            {/* <span
              onClick={() => history('/profile/edit')}
              className='editProfileSpan'
            >
              My Progress
            </span> */}
          </div>
          <div className="contact-details-div">
            <span className="profile-name">Joined on</span>
            <span>12/04/2021</span>
          </div>
        </div>
      ) : (
        <div className="ProfilePage-title-container">
          <div className="ProfilePage-title-row">
            <h1>
              {userData?.firstname} {userData?.lastname}
            </h1>
            <div className="contact-details-div">
              <span className="profile-name">{userData?.phone}</span>
              <span>{userData?.email}</span>
            </div>
          </div>
        </div>
      )}
      <div className="Profile-card-container">
        {/* first card */}
        <div className="Profile-card first-card">
          <div className="d-flex justify-content-around">
            <div>
              {" "}
              <h5>Welcome Bonus</h5>
              <span>{userPoints?.data?.welcomeBonus}</span>
            </div>

            <div>
              {" "}
              <h5>Loyalty Points</h5>
              <span>{userPoints?.data?.loyaltyPoints}</span>
            </div>

            <div>
              {" "}
              <h5>Total Points</h5>
              <span>{TOTAL_POINTS}</span>
            </div>
          </div>

         
          {/* <div className="d-flex justify-content-around mt-5">
  <div>
    <h5>Upcoming Expiry Date and Points</h5>
    
    {userPoints?.data?.points?.map((item, index) => (
      <div  key={index}>
        <p>
          <strong>Loyalty Points:</strong>
          
           {item?.loyaltyPoints}{" "}


          {item?.loyaltyExpiry && (
            <span>(Expiry: {item?.loyaltyExpiry})</span>
          )}
        </p>
        <p>
          <strong>Welcome Bonus:</strong> {item?.welcomeBonus}{" "}
          {item?.welcomeExpiry ? (
            <span>(Expiry: {item?.welcomeExpiry})</span>
          ) : (
            <span>(No Expiry)</span>
          )}
        </p>

        
      </div>
    ))}
  </div>



 
</div> */}


<div className="d-flex justify-content-around mt-5">
  <div>
    <h5>Upcoming Expiry Date and Points</h5>
    {userPoints?.data?.points
      ?.filter(item => item.loyaltyExpiry || item.welcomeExpiry) // Filter out items with no expiry
      ?.sort((a, b) => {
        const loyaltyExpiryA = new Date(a.loyaltyExpiry || "9999-12-31"); // Handle no expiry case
        const welcomeExpiryA = new Date(a.welcomeExpiry || "9999-12-31");
        const earliestExpiryA = loyaltyExpiryA < welcomeExpiryA ? loyaltyExpiryA : welcomeExpiryA;

        const loyaltyExpiryB = new Date(b.loyaltyExpiry || "9999-12-31");
        const welcomeExpiryB = new Date(b.welcomeExpiry || "9999-12-31");
        const earliestExpiryB = loyaltyExpiryB < welcomeExpiryB ? loyaltyExpiryB : welcomeExpiryB;

        return earliestExpiryA - earliestExpiryB; // Sort by earliest expiry
      })
      ?.slice(0, 2) // Limit to the top 3 expiring soonest
      ?.map((item, index) => (
        <div key={index}>
          {console.log(item)}
          <p>
            <span>Loyalty Points:</span> {item?.loyaltyPoints}{" "}
            {item?.loyaltyExpiry && <span>(Expiry: {moment(item?.loyaltyExpiry).format("DD MMM YYYY")})</span>}
          </p>
          <p>
            <span>Welcome Bonus:</span> {item?.welcomeBonus}{" "}
            {item?.welcomeExpiry ? (
              <span>(Expiry: {moment(item?.welcomeExpiry).format("DD MMM YYYY")})</span>
            ) : (
              <span>(No Expiry)</span>
            )}
          </p>
        </div>
      ))}
  </div>
</div>


        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Rewards;
