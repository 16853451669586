import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExamPaymentModal from "./ExamPaymentModal";
import { useDispatch, useSelector } from "react-redux";
import { getUserExams } from "../../../../redux/actions/examActions";







const DashboardScreen = ({ classTest }) => {
  const dispatch=useDispatch();
  const [paymentModal, setPaymentModal] = useState(false);
  const [examData,setExamData]=useState(null);
  const {userData}=useSelector((store)=>store.userReducer)
  const {userExams}=useSelector((store)=>store.examReducer);
  const {userLecturesByYEAR}=useSelector((store)=>store.lectureReducer)
  console.log('userExams', userExams);


console.log('classTest', classTest)




  const isButtonEnabled = (selectDate, startTime, endTime) => {
    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];
    const currentTime = now.toTimeString().split(" ")[0];

    if (currentDate < selectDate) {
      return "comingSoon";
    }

    if (
      currentDate === selectDate &&
      currentTime >= startTime &&
      currentTime <= endTime
    ) {
      return "enabled";
    }

    return "notAvailable";
  };

  console.log('userLecturesByYear', userLecturesByYEAR)


  const navigate = useNavigate();
  const handleNavigate = (item) => {
    console.log('item', item)
    // Check if the user has access to the exam via `userExams`
    const hasExamAccess = userExams?.some((exam) => exam.examId == item?.id);
    console.log('hasExamAccess', hasExamAccess)
  
    // Check if the user has purchased lectures that are still valid
    const hasValidLectureAccess = userLecturesByYEAR?.some((lectures) => {
      const lectureInstances = lectures?.userPurchasedLectures?.length;
      const validTill = lectures?.userPurchasedLectures[lectureInstances - 1]?.validTill;
      const isValid = new Date(validTill) >= new Date(); // Check if the lecture access is still valid
      return lectures?.id === item?.courseId && isValid;
    });

    console.log('hasValidLectureAccess', hasValidLectureAccess)
  
    // If the user has not purchased the exam and there is no valid lecture access, show the payment modal
    if (!hasExamAccess && !hasValidLectureAccess) {
      setPaymentModal(true);
      setExamData(item);
      return;
    }
  
    // If user has access either through exam purchase or valid lecture, navigate to the appropriate test screen
    if (item?.testType === "mcq") {
      navigate("/student-test/student_mcq-test-screen", {
        state: { item: item },
      });
    } else if (item?.testType === "subject_wise") {
      navigate("/student-test/student_subjective-test");
    } else if (item?.testType === "mega_test") {
      navigate("/student-test/student_mega-test");
    }
  };
  

  console.log('classTest', classTest)
  return (
    <Box
      sx={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Test Card */}
      <Box
        sx={{
          width: "100%",
          padding: "20px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: "auto",
        }}
      >
        {classTest &&
          classTest?.length > 0 &&
          classTest?.map((item, index) => {
            const buttonStatus = isButtonEnabled(
              item.select_date,
              item.start_time,
              item.end_time
            );
            return (
              <Card
                sx={{
                  width: "400px",
                  height: "230px",
                  background: "whiteSmoke",
                  margin: "10px",
                }}
                variant="outlined"
                key={index}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom textAlign="center">
                    {item?.examName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography>
                        Exam Type : {item?.testType?.toUpperCase()}
                      </Typography>
                      <Typography>Exam Date : {item?.select_date}</Typography>
                      <Typography>Start Time : {item?.start_time}</Typography>
                      <Typography>End Time : {item?.end_time}</Typography>
                    </Box>

                    <Box>
                      <Typography>
                        Subject : {item?.select_subject.toUpperCase()}
                      </Typography>
                      <Typography>Chapter : {item?.select_chapter}</Typography>
                      <Typography>Class : {item?.select_class}</Typography>
                      <Typography>Marks : {item?.total_marks}</Typography>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    margin: "auto auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    {buttonStatus === "enabled" ? (
                      <Button
                        size="small"
                        variant="contained"
                        className="common-color"
                        onClick={() => handleNavigate(item)}
                      >
                        Start Test
                      </Button>
                    ) : buttonStatus === "comingSoon" ? (
                      <Typography className="badgeColor">
                        Coming Soon
                      </Typography>
                    ) : (
                      <Typography className="badgeColor">
                        Not Available
                      </Typography>
                    )}
                  </Box>
                </CardActions>
              </Card>
            );
          })}
      </Box>

      {classTest && classTest?.length == 0 && (
        <Box
          sx={{
            width: "100%",
            height: "100vh", // Full height to center vertically
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color="grey" variant="h5">
            There is no live test
          </Typography>
        </Box>
      )}
       {paymentModal && (
        <ExamPaymentModal examData={examData} userData={userData} closeModal={() => setPaymentModal(false)} />
      )}
    </Box>

    
  );
};

export default DashboardScreen;
