import React, { Fragment } from 'react';
import '../styles/TransactionCard.css';

// const options = { year: 'numeric', month: 'short', day: '2-digit' };

const ExamTransactionCard = (props) => {
  const { userExams, handleDownloadInvoice } = props;
  
  if(userExams.message==="No exams available") return


  return (
    <Fragment>
      {userExams?.map((exam,index) => {
        return (
          <div className='TransactionCard' key={index}>
            <div className='TransactionCard-row-1'>
              <p className='first-col'>Exam: {`${exam?.exam?.examName}`}</p>
              <p className='second-col'>  ₹{exam?.paidAmount !== exam?.originalAmount ? (
            <>
              {exam?.paidAmount}{' '}
              <span
                style={{
                  textDecorationLine: 'line-through',
                  textDecorationStyle: 'solid',
                  marginLeft: '10px',
                  color: '#999',
                }}
              >
                ₹{exam?.originalAmount}
              </span>
            </>
          ) : (
            exam?.paidAmount
          )} 
          
        </p>
              <p className='third-col'>
               {
                exam?.purchasedOn
               }
              </p>
            </div>
            <div className='TransactionCard-row-2'>
              <p className='all-chapters-text'>
                Exam date: {exam?.exam?.select_date} 
              </p>
              <p
                className='download-text'
                onClick={() => handleDownloadInvoice('exam', exam,'receipt')}
              >
                Download receipt
              </p>
              <p
                className='download-text'
                onClick={() => handleDownloadInvoice('exam', exam,'invoice')}
              >
                Download invoice
              </p>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default ExamTransactionCard;
