import React, { useState, useEffect } from "react";
import Navbar from './Navbar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { LiveClassesDropdown } from './ParentDropdown';
import maleTeacher from "../../images/front-view-man-classroom.jpg";
import { useSelector, useDispatch } from "react-redux";
import { getMeetings } from "../../redux/actions/meetingActions";
import { formatDate } from "../../functions/date";

export const LiveClassParents = () => {
  const dispatch = useDispatch();
  const { meetings } = useSelector((store) => store.meetingReducer);
  const {allExams,selectedStudent}=useSelector((store)=>store.studentReducer)

  useEffect(() => {
    if (!meetings) {
      dispatch(getMeetings());
    }
  }, [dispatch, meetings]);

  const meet = meetings?.data?.meeting
    ? [...meetings.data.meeting].reverse()
    : [];
  const meeetingData = meet;
  const date = new Date();
  const formattedDate = formatDate(date);

  return (
    <div className="slate">
      <Navbar />
      <Container maxWidth="lg" sx={{ mt: 10, overflow: 'auto' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          my={4}
        >
          <Typography
            variant="h4"
            component="h2"
            color="black"
            mb={{ xs: 2, sm: 0 }}
          >
            All Subjects - MCQ
          </Typography>
          <LiveClassesDropdown
            sx={{
              width: { xs: "100%", sm: "auto" },
              fontSize: { xs: "16px", sm: "inherit" },
            }}
          />
        </Box>

        <div className="row">
          {meeetingData?.map((item, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2" key={item?.id}>
              <div
                className="custom-card"
                style={{ maxHeight: "10rem", backgroundColor: 'white' }}
              >
                <div className="custom-flex">
                  <div className="custom-image">
                    <img src={maleTeacher} className="custom-img" alt="Card" />
                  </div>
                  <div className="custom-body">
                    <div className="custom-date">
                      {formattedDate} | 59mins
                    </div>
                    <h5 className="custom-title">
                      {item?.chapter?.length >= 15
                        ? item.chapter.slice(0, 18) + ".."
                        : item.chapter}{" "}
                      | {item?.subject}
                    </h5>
                    <p className="custom-text" style={{ color: 'black' }}>
                      {item?.createdBy}
                    </p>
                    <p className="custom-class" style={{ color: 'black' }}>
                      {item?.class}th
                    </p>

                    <button className="btn btn-success">View Details</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default LiveClassParents;
