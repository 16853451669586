import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { Container, Typography } from '@mui/material';

export const TableRank = ({ data }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const { rankings } = useSelector((store) => store.studentReducer);

  console.log('data from table', rankings);

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Student Rankings
      </Typography>
      <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Student Name</StyledTableCell>
              <StyledTableCell align="center">Subject</StyledTableCell>
              <StyledTableCell align="center">Chapter</StyledTableCell>
              <StyledTableCell align="center">Marks</StyledTableCell>
              <StyledTableCell align="center">Ranking</StyledTableCell>
              <StyledTableCell align="center">Location</StyledTableCell>
              <StyledTableCell align="center">Percentage</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rankings && rankings.length > 0 ? (
              rankings.map((row) => (
                <StyledTableRow key={row?.id}>
                  <StyledTableCell>{row?.userName}</StyledTableCell>
                  <StyledTableCell align="center">{row?.subject}</StyledTableCell>
                  <StyledTableCell align="center">{row?.chapter}</StyledTableCell>
                  <StyledTableCell align="center">{row?.obtainedMarks}</StyledTableCell>
                  <StyledTableCell align="center">{row?.rank}</StyledTableCell>
                  <StyledTableCell align="center">{row?.location}</StyledTableCell>
                  <StyledTableCell align="center">{row?.percentage}%</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={7} align="center">
                  No Info Available
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TableRank;
