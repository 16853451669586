import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import SubscriptionCard from './SubscriptionCard';
import TransactionCard from './TransactionCard';
import EventTransactionCard from './EventTransactionCard';
import '../styles/PurchasesPage.css';
import { getUserEvents } from '../redux/actions/eventActions';
import { getUserCourses } from '../redux/actions/courseActions';

import ExamTransactionCard from './ExamTransactionCard';
import { jsPDF } from 'jspdf';
import ChapterTransactionCard from './ChapterTransactionCard';
import Receipt from './Receipt';
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';
import { getUserExams } from '../redux/actions/examActions';
import { userProjects } from '../redux/actions/projectActions';
import ProjectTransactionCard from './ProjectTransactionCard';
import { getUserAllLectures, getUserLecturesbyYear } from '../redux/actions/lectureActions';
import LectureTransactionCard from './LectureTransactionCard';
import Invoice from './Invoice';

export const PurchasesPage = () => {
  const dispatch = useDispatch();
  const [activeOption, setActiveOption] = useState('subscriptions');
  const [expandView, setExpandView] = useState([]);
  const [boolVal, setBoolval] = useState(false);
  const location = useLocation();
  console.log('location', location)

  const { userCourses,userChapters } = useSelector((store) => store.courseReducer);
  const { userEvents } = useSelector((store) => store.eventReducer);
  const {userExams}=useSelector((store)=>store.examReducer);
  const {userProject}=useSelector((store)=>store.projectReducer)
  const {userAllLiveLectures}=useSelector((store)=>store.lectureReducer)
  const { id, firstname, lastname, email, phone, address, state } = useSelector(
    (store) => store.userReducer?.userData
  );
console.log('user chapters', userChapters)
console.log('user course', userCourses)
//   console.log("user exams",userExams)
//   console.log('user project', userProject)
//  console.log('all live lectures', userAllLiveLectures)

  useEffect(() => {
    if (!boolVal) {
      dispatch(getUserCourses());
      dispatch(getUserEvents());
      
      setBoolval(true);
    }
  }, [dispatch, boolVal]);


  useEffect(()=>{
dispatch(getUserExams())
dispatch(userProjects())
dispatch(getUserLecturesbyYear())
dispatch(getUserAllLectures())
  },[])

  const handleViewClick = (sub) => {
    var index = expandView.indexOf(sub);
    let newArr = [];
    if (index === -1) {
      newArr = [...expandView, sub];
    } else {
      newArr = [...expandView];
      newArr.splice(index, 1);
    }
    setExpandView(newArr);
  };
  console.log('userCourses', userCourses)

//   const handleDownloadInvoice = async (type, data,acknowledgementType) => {
//     // const inNumber = `0000560/${new Date(
//     //   data.UserEvents[data.UserEvents.length - 1].purchasedOn
//     // ).getDate()}${new Date().getMonth().toLocaleString('en-US', {
//     //   minimumIntegerDigits: 2,
//     //   useGrouping: false,
//     // })}${new Date().getFullYear()}/${id}`;
//     let billData = {
//       userName: firstname + ' ' + lastname,
//       address: address,
//       state: state,
//       phone: phone,
//       email: email,
//     };
//     if (type === 'event') {
//       billData = {
//         ...billData,
//         invoiceNumber: `${acknowledgementType==='receipt'?'/ET':'/IN-ET'}/${new Date(
//           data.UserEvents[data.UserEvents.length - 1].purchasedOn
//         )
//           .getDate()
//           .toLocaleString('en-US', {
//             minimumIntegerDigits: 2,
//             useGrouping: false,
//           })}${(
//           new Date(
//             data.UserEvents[data.UserEvents.length - 1].purchasedOn
//           ).getMonth() + 1
//         ).toLocaleString('en-US', {
//           minimumIntegerDigits: 2,
//           useGrouping: false,
//         })}${new Date(
//           data.UserEvents[data.UserEvents.length - 1].purchasedOn
//         ).getFullYear()}/${id}/${data.id}`,
//         issueData: new Date(
//           data.UserEvents[data.UserEvents.length - 1].purchasedOn
//         ),
//         description: `Event - ${data.topic} ${
//           data.UserEvents[data.UserEvents.length - 1].isSolo === true
//             ? '(solo purchase)'
//             : '(group purchase)'
//         }`,
//         amount:
//           data.UserEvents[data.UserEvents.length - 1].isSolo === true
//             ? data.soloPrice
//             : data.groupPrice,
//       };
//     }
//     if (type === 'course') {
//       billData = {
//         ...billData,
//         invoiceNumber: `0000560/${new Date(
//           data.UserCourses[data.UserCourses.length - 1].purchasedOn
//         )
//           .getDate()
//           .toLocaleString('en-US', {
//             minimumIntegerDigits: 2,
//             useGrouping: false,
//           })}${(
//           new Date(
//             data.UserCourses[data.UserCourses.length - 1].purchasedOn
//           ).getMonth() + 1
//         ).toLocaleString('en-US', {
//           minimumIntegerDigits: 2,
//           useGrouping: false,
//         })}${new Date(
//           data.UserCourses[data.UserCourses.length - 1].purchasedOn
//         ).getFullYear()}/${id}/${data.id}`,
//         issueData: new Date(),
//         description: `Course - (${data.subject}, ${data.class}th standard ${data.board})`,
//         amount: 1000,
//       };
//     }

//     if (type === 'exam') {
//       billData = {
//         ...billData,
//         invoiceNumber: `0000560/${new Date(
//           data.UserWorkshops[data.UserWorkshops.length - 1].purchasedOn
//         )
//           .getDate()
//           .toLocaleString('en-US', {
//             minimumIntegerDigits: 2,
//             useGrouping: false,
//           })}${(
//           new Date(
//             data.UserWorkshops[data.UserWorkshops.length - 1].purchasedOn
//           ).getMonth() + 1
//         ).toLocaleString('en-US', {
//           minimumIntegerDigits: 2,
//           useGrouping: false,
//         })}${new Date(
//           data.UserWorkshops[data.UserWorkshops.length - 1].purchasedOn
//         ).getFullYear()}/${id}/${data.id}`,
//         issueData: new Date(),
//         description: `Exam - Topic: (${data?.examName})`,
//         amount: data.price,
//       };
//     }

//     if (type === 'project') {
//       billData = {
//         ...billData,
//         invoiceNumber: `/IN-PR/${new Date(
//           data?.purchasedOn
//         )
//           .getDate()
//           .toLocaleString('en-US', {
//             minimumIntegerDigits: 2,
//             useGrouping: false,
//           })}${(
//           new Date(
//             data?.purchasedOn
//           ).getMonth() + 1
//         ).toLocaleString('en-US', {
//           minimumIntegerDigits: 2,
//           useGrouping: false,
//         })}${new Date(
//           data?.purchasedOn
//         ).getFullYear()}/${id}/${data.id}`,
//         issueData: new Date(),
//         description: `Project - Topic: (${data?.project_name})`,
//         amount: data?.paidAmount,
//       };
//     }

//     // var doc = new jsPDF('p', 'pt', 'a4');
//     // doc.html(
//     //   ReactDOMServer.renderToStaticMarkup(<Receipt billData={billData} />),
//     //   {
//     //     callback: function (pdf) {
//     //       var pageCount = doc.internal.getNumberOfPages();
//     //       pdf.deletePage(pageCount);
//     //       pdf.save('receipt.pdf');
//     //     },
//     //   }
//     // );


//   //     Generate HTML content for the receipt
//   let receiptElement;

// if (acknowledgementType === 'receipt') {
//   receiptElement = <Receipt billData={billData} />;
// } else {
//   receiptElement = <Invoice billData={billData} />;
// }
 

//   const elementToRender = document.createElement('div');
//   document.body.appendChild(elementToRender);
//   ReactDOM.render(receiptElement, elementToRender);
//     const canvas = await html2canvas(elementToRender);

//     const imgData = canvas.toDataURL('image/png');
//     const pdf = new jsPDF('p', 'mm', 'a4');
    
//     // Adjust for width and height scaling
//     const imgWidth = 210; // A4 width in mm
//     const pageHeight = 295; // A4 height in mm
//     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
//     let heightLeft = imgHeight;
//     let position = 0;
  
//     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
//     heightLeft -= pageHeight;
  
//     while (heightLeft >= 0) {
//       position = heightLeft - imgHeight;
//       pdf.addPage();
//       pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
//       heightLeft -= pageHeight;
//     }
//   acknowledgementType==='receipt'?
//     pdf.save('receipt.pdf'): pdf.save('invoice.pdf')
  
//     // Clean up after rendering
//     // Clean up after rendering
//   ReactDOM.unmountComponentAtNode(elementToRender);
//   document.body.removeChild(elementToRender);

// };

const handleDownloadInvoice = async (type, data, acknowledgementType) => {
  let billData = {
    userName: firstname + ' ' + lastname,
    address: address,
    state: state,
    phone: phone,
    email: email,
  };

  // Get current date and time for invoice/receipt number
  const currentDate = new Date();
  const dateString = currentDate
    .toISOString()
    .split('T')[0]
    .replace(/-/g, ''); // YYYYMMDD
  const timeString = currentDate
    .toTimeString()
    .split(' ')[0]
    .replace(/:/g, ''); // HHMMSS

  const typePrefix = acknowledgementType === 'receipt' ? "" : 'INV';

  const generateInvoiceNumber = (purchaseDate, id, dataType) => {
    return `${typePrefix}/${dataType}-${id}${dateString}-${timeString}`;
  };

  if (type === 'event') {
    const purchaseDate = new Date(data.UserEvents[data.UserEvents.length - 1].purchasedOn);
    billData = {
      ...billData,
      invoiceNumber: generateInvoiceNumber(purchaseDate, id, 'EV'),
      issueDate: purchaseDate,
      description: `Event - ${data.topic} ${
        data.UserEvents[data.UserEvents.length - 1].isSolo ? '(solo purchase)' : '(group purchase)'
      }`,
      amount: data.UserEvents[data.UserEvents.length - 1].isSolo ? data.soloPrice : data.groupPrice,
    };
  }

  if (type === 'course') {
    const purchaseDate = new Date(data.UserCourses[data.UserCourses.length - 1].purchasedOn);
    billData = {
      ...billData,
      invoiceNumber: generateInvoiceNumber(purchaseDate, data.id, 'CR'),
      issueDate: purchaseDate,
      description: `Course - (${data?.subject}, ${data?.class}th standard ${data.board})`,
      amount: data.UserCourses[data.UserCourses.length - 1]?.paidAmount,
    };
  }

  if (type === 'chapter') {
    const purchaseDate = new Date(data.UserChapters[data?.UserChapters?.length - 1]?.purchasedOn);
    billData = {
      ...billData,
      invoiceNumber: generateInvoiceNumber(purchaseDate, id, 'CH'),
      issueDate: purchaseDate,
      description: `Chapter- (${data?.name})`,
      amount: data?.UserChapters[data?.UserChapters?.length - 1]?.paidAmount,
    };
  }

  if (type === 'exam') {
    const purchaseDate = new Date(data?.purchasedOn);
    billData = {
      ...billData,
      invoiceNumber: generateInvoiceNumber(purchaseDate, id, 'EX'),
      issueDate: purchaseDate,
      description: `Exam - Topic: (${data?.exam?.examName})`,
      amount: data?.paidAmount,
    };
  }

  if (type === 'project') {
    const purchaseDate = new Date(data?.purchasedOn);
    billData = {
      ...billData,
      invoiceNumber: generateInvoiceNumber(purchaseDate, id, 'PR'),
      issueDate: purchaseDate,
      description: `Project - Topic: (${data?.project?.project_name})`,
      amount: data?.paidAmount,
    };
  }

  if (type === 'live') {
    const purchaseDate = new Date(data?.purchasedOn);
    billData = {
      ...billData,
      invoiceNumber: generateInvoiceNumber(purchaseDate, id,'LV'),
      issueDate: purchaseDate,
      description: `Live: (${data?.course?.subject})`,
      amount: data?.paidAmount,
    };
  }

  // Generate PDF logic
  let receiptElement;

  if (acknowledgementType === 'receipt') {
    receiptElement = <Receipt billData={billData} />;
  } else {
    receiptElement = <Invoice billData={billData} />;
  }

  const elementToRender = document.createElement('div');
  document.body.appendChild(elementToRender);
  ReactDOM.render(receiptElement, elementToRender);
  
  const canvas = await html2canvas(elementToRender);
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
  
  const imgWidth = 210; // A4 width in mm
  const pageHeight = 295; // A4 height in mm
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;

  pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    pdf.addPage();
    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }

  pdf.save(acknowledgementType === 'receipt' ? 'receipt.pdf' : 'invoice.pdf');

  ReactDOM.unmountComponentAtNode(elementToRender);
  document.body.removeChild(elementToRender);
};

  return (
    <div className='PurchasesPage'>
      <Navbar activeMenuItem={'profile'} />
      <div className='PurchasesPage-title-container'>
        <h1>{location.state === 'purchases' ? 'My purchases' : 'My notes'}</h1>
       

      {/* <h1>{location?.state === 'purchases' ? 'My purchases' : 'My notes'}  </h1> */}
        <div className='PurchasesPage-options-container'>
          {location.state === 'purchases' ? (
            <Fragment>
              <span
                onClick={() => setActiveOption('subscriptions')}
                className={activeOption === 'subscriptions' && 'active-option'}
              >
                Manage Subscriptions
              </span>
              <span
                onClick={() => setActiveOption('transactions')}
                className={activeOption === 'transactions' && 'active-option'}
              >
                Transactions
              </span>
            </Fragment>
          ) : (
            <span>All notes you have subscribes are here.</span>
          )}
        </div>
      </div>
      {activeOption === 'transactions' ? (
        <div className='transactions-card-container'>
          <div className='transactions-card-container-title-row'>
            <p className='first-title'>My purchases</p>
            <p className='middle-title'>Price</p>
            <p className='last-title'>Purchase Date</p>
          </div>
          {userCourses?.map((course) => (
            <TransactionCard
              purchaseDetails={course.UserCourses}
              standard={course.class}
              subject={course.subject}
              yearlyPrice={course.yearlyPrice}
              monthlyPrice={course.monthlyPrice}
              quarterlyPrice={course.quarterlyPrice}
              course={course}
              date={course.createdAt}
              handleDownloadInvoice={handleDownloadInvoice}
            />
          ))}

          {
            userChapters?.map((chapter)=>(
              <ChapterTransactionCard
              purchaseDetails={chapter.UserChapters}
              standard={chapter.class}
              subject={chapter.name}
              yearlyPrice={chapter.ch_yearly_price}
              monthlyPrice={chapter.ch_monthly_price}
              quarterlyPrice={chapter.ch_quarterly_price}
              chapter={chapter}
              handleDownloadInvoice={handleDownloadInvoice}
              
              />
            ))
          }

    <ExamTransactionCard
    userExams={userExams}
    handleDownloadInvoice={handleDownloadInvoice}
  />

<ProjectTransactionCard
    userProject={userProject}
    handleDownloadInvoice={handleDownloadInvoice}
  />

  <LectureTransactionCard
 userLiveLecture={userAllLiveLectures}
 handleDownloadInvoice={handleDownloadInvoice}/>


          
          <EventTransactionCard
            userEvents={userEvents}
            handleDownloadInvoice={handleDownloadInvoice}
          />
         
        </div>
      ) : (
        <div className='subscriptions-card-content'>
          {userCourses?.map((course, index) => (
            <div className='subscriptions-card-container' key={index}>
              <p className='subscriptions-card-title'>
                Class {course.class}th : {course.subject} notes
              </p>
              {course?.chapters
                ?.sort((a, b) =>
                  a.number > b.number ? 1 : b.number > a.number ? -1 : 0
                )
                .map((chapter, idx) =>
                  idx <= 1 ? (
                    <SubscriptionCard chapter={chapter} />
                  ) : (
                    expandView.includes(course.id) && (
                      <SubscriptionCard chapter={chapter} />
                    )
                  )
                )}
              <div className='view-toggle'>
                <span onClick={() => handleViewClick(course.id)}>
                  {expandView.includes(course.id) ? 'View less' : 'View more'}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default PurchasesPage;


















