import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buyCourse, buyChapter, verifyCoursePayment, verifyChapterPayment } from '../redux/actions/api/index';
import { useNavigate } from 'react-router-dom';
import unlockImage from '../images/img_unlock.jpg';
import closeIcon from '../images/ic_close.svg';
import ecLogo from '../images/ec_logo_square.jpg';
import '../styles/UnlockChapterModal.css';
import { toast } from 'react-toastify';
import { getUserChapters, getUserCourses } from '../redux/actions/courseActions';
import { getUserPoints } from '../redux/actions/userActions';

const UnlockChapterModal = ({ handleClose, course, unlockType, chapter }) => {

  console.log('unlocktype============>', unlockType)
  console.log('chapter is==> ', chapter )
console.log("course is===>",course)
const isChapter = unlockType === 'chapter';
console.log('isCHpater', isChapter)


  const dispatch=useDispatch();
  const navigate = useNavigate();
  const [showRewardSelection, setShowRewardSelection] = useState(false);
  const [finalPrice, setFinalPrice] = useState({
    monthly: isChapter? chapter.ch_monthly_price : course.monthlyPrice,
    quarterly: isChapter? chapter.ch_quarterly_price  : course.quarterlyPrice,
    yearly: isChapter? chapter.ch_yearly_price   : course.yearlyPrice,
  });
  const [selectedReward, setSelectedReward] = useState(null);
  const { userPoints, userData } = useSelector((store) => store.userReducer);
  const [pointsUsed, setPointsUsed] = useState({
    monthly: 0,
    quarterly: 0,
    yearly: 0,
  });

  
  useEffect(() => {
    dispatch(getUserPoints());
  }, []);



 

  const applyReward = (rewardType) => {
    const rewardAmount = rewardType === 'welcomeBonus' ?( Math.floor(userPoints?.data?.welcomeBonus)):( Math.floor(userPoints?.data?.loyaltyPoints));
    setPointsUsed({
      yearly: Math.min(finalPrice.yearly - 1, Math.floor(rewardAmount)),
      quarterly: Math.min(finalPrice.quarterly - 1, rewardAmount),
      monthly: Math.min(finalPrice.monthly - 1, rewardAmount),
    });
    setSelectedReward(rewardType);
    setFinalPrice({
      yearly: Math.max(finalPrice.yearly - rewardAmount, 1),
      quarterly: Math.max(finalPrice.quarterly - rewardAmount, 1),
      monthly: Math.max(finalPrice.monthly - rewardAmount, 1),
    });
    setShowRewardSelection(false);
  };

  const removeReward = () => {
    setSelectedReward(null);
    setPointsUsed({ monthly: 0, quarterly: 0, yearly: 0 });
    setFinalPrice({
      monthly: isChapter?chapter.ch_monthly_price : course.monthlyPrice,
      quarterly: isChapter? chapter.ch_quarterly_price  : course.quarterlyPrice,
      yearly: isChapter? chapter.ch_yearly_price   : course.yearlyPrice,
    });
  };

  const handleBuyNow = async (subscriptionType, amount, rewardType, isChapter) => {
    console.log('data', subscriptionType,amount,rewardType,isChapter)
    try {
      let usedPoints = pointsUsed[subscriptionType.toLowerCase()];
      console.log('usedPoints', usedPoints)
      let response;

      if (isChapter===true) {
        response = await buyChapter(chapter.id, { subscriptionType, amount, rewardType, pointsUsed: usedPoints });
      } else {
        response = await buyCourse(course.id, { subscriptionType, amount, rewardType, pointsUsed: usedPoints });
      }

      console.log('response', response)

      if (response.status !== 201) throw new Error('Purchase failed');

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: response.data.response.amount,
        currency: response.data.response.currency,
        name: 'Enrouting Careers',
        description: response.data.response.notes.desc,
        image: ecLogo,
        order_id: response.data.response.id,
        handler: async (paymentResponse) => {
          const paymentDetails = {
            orderCreationId: response.data.response.id,
            razorpayPaymentId: paymentResponse.razorpay_payment_id,
            razorpaySignature: paymentResponse.razorpay_signature,
            itemId: isChapter ? chapter.id : course.id,
            userId: userData.id,
            subscriptionType,
            rewardType,
            amount,
            pointsUsed: usedPoints
          };
          if (isChapter) {
            await verifyChapterPayment(paymentDetails);
          } else {
            await verifyCoursePayment(paymentDetails);
          }
          handleClose();
          document.body.style.overflow = 'auto';
          dispatch(getUserChapters())
          dispatch(getUserCourses())
          navigate('/subscription', { state: {  item: isChapter ? chapter : course, paymentDetails, type: isChapter ? 'chapter' : 'course' } });
        },
        prefill: { name: `${userData.firstname} ${userData.lastname}`, email: userData.email, contact: userData.phone },
        theme: { color: '#3399cc' },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on('payment.failed', handleClose);
    } catch (error) {
      console.error('Error during purchase:', error);
      toast.error(error.message || 'An error occurred during the purchase');
    }finally {
      document.body.style.overflow = 'auto';
    }
  };

  const CourseOrChapterDetails = ({ subscriptionType, price, originalPrice, duration, isChapter }) => (
    <div className='UnlockChapterModal-text-container'>
      <h1>Unlock {isChapter ? 'Chapter' : 'Course'}</h1>
      <p>Get complete access by purchasing this {isChapter? 'chapter' : 'course'} for {duration}.</p>
      <div className='UnlockChapterModal-details-container'>
        <div className='UnlockChapterModal-details-container-col-left'>
          <span>Subject name</span>
          <span>Board</span>
          <span>Standard / Class</span>
          <span>Subscription cost</span>
        </div>
        <div className='UnlockChapterModal-details-container-col-right'>
          <span>{course.subject}</span>
          <span>{course.board}</span>
          <span>{course.class}th standard</span>
          <span>
            ₹{price !== originalPrice ? (
              <>
                {price}{' '}
                <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                  {originalPrice}
                </span>
              </>
            ) : price} for {duration}
          </span>
        </div>
      </div>
      <button 
        onClick={() => handleBuyNow(subscriptionType, price, selectedReward, isChapter)} 
        className='UnlockChapterModal-button'>
        Buy now
      </button>
    </div>
  );

  

const RewardSelection = () => (
  <div className='reward-selection-modal'>
    <h2>Select a reward to apply</h2>
    <button 
disabled={userPoints?.data?.welcomeBonus === 0} 
onClick={() => applyReward('welcomeBonus')}>
Use Welcome Bonus (₹ {userPoints?.data?.welcomeBonus})
</button>
<button 
disabled={userPoints?.data?.loyaltyPoints === 0} 
onClick={() => applyReward('loyaltyPoints')}>
Use Loyalty Points (₹ {userPoints?.data?.loyaltyPoints})
</button>

    <button onClick={() => setShowRewardSelection(false)}>Cancel</button>
  </div>
);

  return (
    <div className='UnlockChapterModal'>
      <div className='UnlockChapterModal-content'>
        <span onClick={handleClose}>
          <img src={closeIcon} alt='close-icon' className='UnlockChapterModal-close-icon' />
        </span>
        <div className='UnlockChapterModal-content-container'>
          {/* <img src={unlockImage} alt='unlock-img' className='unlock-img' /> */}

          {showRewardSelection ? (
            <RewardSelection />
          ) : (
            <>
              <CourseOrChapterDetails
                subscriptionType='Yearly'
                price={finalPrice.yearly}
                originalPrice={isChapter? chapter.ch_yearly_price : course.yearlyPrice}
                duration='1 year'
                isChapter={isChapter}
             
              />
              <CourseOrChapterDetails
                subscriptionType='Quarterly'
                price={finalPrice.quarterly}
                originalPrice={isChapter?  chapter.ch_quarterly_price : course.quarterlyPrice}
                duration='3 months'
                isChapter={isChapter}
                
               
              />
              <CourseOrChapterDetails
                subscriptionType='Monthly'
                price={finalPrice.monthly}
                originalPrice={isChapter? chapter.ch_monthly_price : course.monthlyPrice}
                duration='1 month'
                isChapter={isChapter}
                
              />
              {!selectedReward ? (
                <div className='reward-info'>
                  <button onClick={() => setShowRewardSelection(true)}>Use Points</button>
                </div>
              ) : (
                <div className='reward-info'>
                  <p>
                    <strong>
                      {selectedReward === 'welcomeBonus' ? 'Welcome Bonus' : 'Loyalty Points'} applied!
                    </strong>
                    <button onClick={removeReward}>Remove</button>
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnlockChapterModal;
