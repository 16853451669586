import React, { Fragment } from 'react';
import '../styles/TransactionCard.css';

// const options = { year: 'numeric', month: 'short', day: '2-digit' };

const EventTransactionCard = (props) => {
  const { userEvents, handleDownloadInvoice } = props;
  return (
    <Fragment>
      {userEvents?.map((event,index) => {
        return (
          <div className='TransactionCard' key={index}>
            <div className='TransactionCard-row-1'>
              <p className='first-col'>
                Event Name:{' '}
                {`${event.topic} (${
                  event.UserEvents[event.UserEvents.length - 1].isSolo
                    ? 'Solo'
                    : 'Group'
                })`}
              </p>
              <p className='second-col'>
                ₹{event.UserEvents[event.UserEvents.length - 1].paidAmount}
              </p>
              <p className='third-col'>
                {event.UserEvents[event.UserEvents.length - 1].purchasedOn}
              </p>
            </div>
            <div className='TransactionCard-row-2'>
              <p className='all-chapters-text'>
                Valid till:{' '}
                {event.UserEvents[event.UserEvents.length - 1].validTill}
              </p>

              <div className='flex'>
              <p
                className='download-text'
                onClick={() => handleDownloadInvoice('event', event, 'receipt')}
              >
                Download receipt
              </p>
              <p
                className='download-text'
                onClick={() => handleDownloadInvoice('event', event, 'invoice')}
              >
                Download invoice
              </p>
              </div>
              
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default EventTransactionCard;
