// ExamPaymentModal.js
import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, TextField } from "@mui/material";
import ecLogo from "../../../../images/ec_logo_square.jpg";
import { purchaseExam,verifyExam } from "../../../../redux/actions/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserExams } from "../../../../redux/actions/examActions";
import { getUserPoints } from "../../../../redux/actions/userActions";

const ExamPaymentModal = ({ examData, closeModal, userData }) => {
  const [showPointsModal, setShowPointsModal] = useState(false);
  const [usedPoints, setUsedPoints] = useState(0);
  const [selectedReward, setSelectedReward] = useState("");
  const [finalPrice, setFinalPrice] = useState(examData?.price);
  const navigate = useNavigate();
  const userId=userData?.id;
  const dispatch=useDispatch();
  const { userPoints } = useSelector((store) => store.userReducer);

  

  useEffect(() => {
    dispatch(getUserPoints());
  }, []);

  

  const applyReward = (rewardType) => {
    // Determine the reward amount based on the selected reward type
    const rewardAmount =
      rewardType === "welcomeBonus"
        ? Math.floor(userPoints?.data?.welcomeBonus)
        : Math.floor(userPoints?.data?.loyaltyPoints);
  
    // Calculate the discount based on the reward amount and the final price
    const discount = Math.min(finalPrice - 1, rewardAmount);
    console.log('Discount applied:', discount);
  
    // Update the used points state with the discount
    setUsedPoints(discount);
  
    // Update the selected reward type
    setSelectedReward(rewardType);
  
    // Calculate and update the final price, ensuring it doesn't drop below 1
    setFinalPrice(prevPrice => Math.max(prevPrice - discount, 1));
  
    // Close the points modal
    setShowPointsModal(false);
  };


  

  const handlePayment = async (
    price,
    selectedReward,
    pointsUsed,
    examId,
    courseId,
    userId
  ) => {
    try {
      const response = await purchaseExam({
        amount: price,
        pointsUsed: pointsUsed,
        examId: examId,
        courseId: courseId,
        userId: userId,
      });

      if (response.status !== 201) throw new Error("Purchase failed");

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: response.data.response.amount,
        currency: response.data.response.currency,
        name: "Enrouting Careers",
        description: response.data.response.notes.desc,
        image: ecLogo,
        order_id: response.data.response.id,
        handler: async (paymentResponse) => {
          try {
            const paymentDetails = {
              orderCreationId: response.data.response.id,
              razorpayPaymentId: paymentResponse.razorpay_payment_id,
              razorpaySignature: paymentResponse.razorpay_signature,
              userId: userId,
              amount: price,
              examId: examId,
              courseId: courseId,
              rewardType: selectedReward,
              pointsUsed: pointsUsed,
            };

            const verifyResponse = await verifyExam(paymentDetails);
            if (verifyResponse.status === 200) {
              dispatch(getUserExams());

          closeModal();
          document.body.style.overflow = "auto";

              navigate('/subscription', {
                state: {
                  item: { paymentDetails, type: 'examFees' },
                },
              });
            } else {
              throw new Error('Payment verification failed');
            }
          } catch (error) {
            console.error("Error verifying payment:", error);
            toast.error("Payment verification failed");
          }

        },
        prefill: {
          name: `${userData.firstname} ${userData.lastname}`,
          email: userData.email,
          contact: userData.phone,
        },
        theme: { color: "#3399cc" },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on("payment.failed", () => {
        toast.error("Payment failed");
        closeModal();
        document.body.style.overflow = "auto";
      });
    } catch (error) {
      console.error("Error during purchase:", error);
      toast.error(error.message || "An error occurred during the purchase");
    } finally {
      document.body.style.overflow = "auto";
    }
  };

  const removeReward = () => {
    setSelectedReward(null);
    setUsedPoints(0);
    setFinalPrice(examData?.price);
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          maxWidth: 400,
          margin: "auto",
          mt: "10%",
        }}
      >
        {/* Conditional rendering based on the state */}
        {showPointsModal ? (
          // Points Modal
          <>
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
            >
              Use Reward Points
            </Typography>
            <Typography sx={{ mt: 2 }} textAlign="center">
              Would you like to use your points to pay ₹{finalPrice} for
              the test?
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Button
                variant="contained"
                disabled={userPoints?.data?.welcomeBonus=== 0}
                onClick={() => applyReward("welcomeBonus")}
              >
                Use Welcome Bonus
              </Button>

              <Button
                variant="contained"
                disabled={userPoints?.data?.loyaltyPoints === 0}
                onClick={() => applyReward("loyaltyPoints")}
              >
                Use Loyalty Points
              </Button>

              <Button
                variant="outlined"
                onClick={() => setShowPointsModal(false)}
                sx={{ ml: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </>
        ) : (
          // Payment Modal
          <>
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
            >
              Payment Required
            </Typography>
            <Typography
              id="modal-description"
              sx={{ mt: 2 }}
              textAlign="center"
            >
              Please complete your payment to start the test.
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center", mt: 1 }}>
            ₹{examData?.price > finalPrice ? (
              <>
                {finalPrice}{' '}
                <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                  {examData?.price}
                </span>
              </>
            ) : examData?.price} 
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Button
                variant="contained"
                onClick={() =>
                  handlePayment(
                    finalPrice,
                    selectedReward,
                    usedPoints,
                    examData.id,
                    examData.courseId,
                    userId
                  )
                }
              >
                Pay Now
              </Button>

              {!selectedReward ? (
                <Button
                  variant="contained"
                  onClick={() => setShowPointsModal(true)}
                  sx={{ ml: 2 }}
                >
                  Use Points
                </Button>
              ) : (
                <div>
                  <strong>
                    {selectedReward === "welcomeBonus"
                      ? "Welcome Bonus"
                      : "Loyalty Points"}
                    applied!
                  </strong>
                  <button onClick={removeReward}>Remove</button>
                </div>
              )}

              <Button variant="outlined" onClick={closeModal} sx={{ ml: 2 }}>
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ExamPaymentModal;
