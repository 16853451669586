
import React, { useEffect } from 'react';
import '../styles/Zoom.css'
import { ZoomMtg } from '@zoom/meetingsdk';
import { useLocation } from 'react-router-dom';
import config from '../config';



function Lobby() {


  useEffect(()=>{// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.3.5/lib', '/av');

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    
  },[])


  

  const location = useLocation();
  const ViewMeet = location?.state?.studentMeetData
  const authEndpoint = `${config.BASE_URL}/api/signature`;  // Ensure this is correct and points to your backend
  const sdkKey = config.ZOOM_SDK_KEY;
  const meetingNumber = ViewMeet?.meetId?.toString();
  const passWord = ViewMeet?.password?.toString();
  const role = 0;
  const userName = 'Student';
  const userEmail = 'user@gmail.com';
  const registrantToken = '';
  const zakToken = '';
  const leaveUrl =  'https://enroutingcareers.com/live'; //'http://localhost:3000/live'; //'https://enroutingcareers.com/live';

  console.log('mee', meetingNumber)

  const getSignature = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(authEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ meetingNumber, role })
      });
      const response = await res.json();
      startMeeting(response.signature);
    } catch (error) {
      console.error(error);
    }
  };

  const startMeeting = (signature) => {
     document.getElementById('zmmtg-root').style.display = 'block';

    ZoomMtg.init({
      leaveUrl,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      disablePreview: true,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature,
          sdkKey,
          meetingNumber,
          passWord,
          userName,
          userEmail,
         
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          }
        });
      },
      error: (error) => {
        console.log(error);
      }
    });
  };

  return (
    <div className="room-container">
    <h1>Zoom Meeting</h1>
    <button onClick={getSignature}>Join Meeting</button>
  </div>
  );
}

export default Lobby;

