import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { getMeetings } from '../../../redux/actions/meetingActions';
import { useNavigate } from 'react-router-dom';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function ParentLiveCards() {

    const dispatch = useDispatch();
    const navigate=useNavigate();
    const { meetings } = useSelector((store) => store.meetingReducer);
    const {allExams,selectedStudent}=useSelector((store)=>store.studentReducer)
    useEffect(() => {
        if (!meetings) {
          dispatch(getMeetings());
        }
      }, [dispatch, meetings]);
    


  const filteredLiveClasses=meetings?.data?.meeting?.filter((item)=>  item?.class===selectedStudent?.class?.toString())

 // console.log('filteredLIve',selectedStudent.firstname ,filteredLiveClasses)
  
  return (
    <Card sx={{ maxwidth: 350}} className='mb-5'>
      <CardContent>
      <Typography sx={{ fontSize: 15 ,fontWeight:600,color:'#383838'}}   gutterBottom>
      Total Live Classes
        </Typography>
        <Typography variant="h2" sx={{fontWeight:500, background: 'linear-gradient(to right, #fb794a, #edb038)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',}} component="div">
         {filteredLiveClasses?.length}
        </Typography>
        <Typography  color="#383838">
          Overall Attendance : 25 %< br/>
          
        </Typography>
     
      </CardContent>
      <CardActions >
        <Button size="small" onClick={()=>navigate("/parents/liveclass")}>VIEW</Button>
      </CardActions>
    </Card>
  );
}