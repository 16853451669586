import React, { useEffect } from 'react';
import '../../styles/Zoom.css';
import { ZoomMtg } from '@zoom/meetingsdk';
import { useLocation } from 'react-router-dom';
import config from '../../config';

import logo from "../../images/ec_logo_nobg.png";
const Room = () => {
  useEffect(() => {
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
  }, []);

  const location = useLocation();
  const ViewMeet = location?.state?.meetData;
  console.log('view meet', ViewMeet);

  const authEndpoint = `${config.BASE_URL}/api/signature`;
  const sdkKey = config.ZOOM_SDK_KEY;
  const meetingNumber = ViewMeet?.meetId?.toString();
  const passWord = ViewMeet?.password?.toString();
  const role = 1;
  const userName = 'Enrouting Careers';
  const userEmail = '';
  const registrantToken = '';
  const zakToken = '';
  const leaveUrl = 'http://localhost:3000/teacher-home';

  const getSignature = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(authEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ meetingNumber, role }),
      });
      const response = await res.json();
      startMeeting(response.signature);
    } catch (error) {
      console.error(error);
    }
  };

  const startMeeting = (signature) => {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      isSupportAV: true,
      isSupportBreakout: false,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature,
          sdkKey,
          meetingNumber,
          passWord,
          userName,
          userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success);
            ZoomMtg.record({
              record: true,
            });
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <div className="room-container">
      <div className="image-container">
        <img src={logo} alt="Zoom Meeting" style={{ height: '200px', objectFit: 'cover' }} />
      </div>
      <h1>Welcome To Zoom Meeting</h1>
      <button className="btn fancy-btn" onClick={getSignature}>Join Meeting</button>
    </div>
  );
};

export default Room;
