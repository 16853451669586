// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import ReadWatchModal from './ReadWatchModal';
// import UnlockChapterModal from './UnlockChapterModal';
// import LoginModal from './LoginModal';
// import lockIcon from '../images/ic_lock.svg';
// import nextIcon from '../images/ic_arrow_right.svg';
// import '../styles/ChapterCard.css';
// import { getUserCourses ,getUserChapters} from '../redux/actions/courseActions';

// const ChapterCard = (props) => {
//   const { isLocked, chapter, course } = props;
//   const [notesModal, setNotesModal] = useState(false);
//   const [loginModal, setLoginModal] = useState(false);
//   const [lecturesModal, setLecturesModal] = useState(false);
//   const [unlockChapterModal, setUnlockChapterModal] = useState(false);
//   const [unlockType, setUnlockType] = useState(''); // New state to track unlock type

//   const { isAuthenticated } = useSelector((store) => store.userReducer);

//   const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(getUserCourses());
//     dispatch(getUserChapters())
//    // console.log('useeffect');
//   }, []);

//   const handleOpen = (type) => {
//     if (type === 'notes') {
//       setNotesModal(true);
//     } else if (type === 'lectures') {
//       setLecturesModal(true);
//     } else if (type === 'login') {
//       setLoginModal(true);
//     } else {
//       setUnlockChapterModal(true);
//     }
//   };

//   const handleClose = () => {
//     setNotesModal(false);
//     setLecturesModal(false);
//     setUnlockChapterModal(false);
//     setLoginModal(false);
//   };

//   const handleUnlockClick = (unlockOption) => {
//     setUnlockType(unlockOption); // Set the unlock type based on user choice
//     isAuthenticated ? handleOpen('unlockChapter') : handleOpen('login');
//   };

//   (notesModal || lecturesModal || unlockChapterModal)
//     ? document.querySelector('body').style.overflow = 'hidden'
//     : document.querySelector('body').style.overflow = 'auto';

//   return (
//     <div className="ChapterCard">
//       {notesModal && (
//         <ReadWatchModal
//           type={'notes'}
//           handleClose={handleClose}
//           chapter={chapter}
//         />
//       )}
//       {lecturesModal && (
//         <ReadWatchModal
//           type={'lectures'}
//           handleClose={handleClose}
//           chapter={chapter}
//         />
//       )}
//       {unlockChapterModal && (
//         <UnlockChapterModal
//           handleClose={handleClose}
//           course={course}
//           chapter={chapter}
//           unlockType={unlockType} // Pass the unlock type to the modal
//         />
//       )}
//       {loginModal && <LoginModal setLoginModal={handleClose} />}
//       <div className="ChapterCard-left">
//         <span>Chapter {chapter.number}</span>
//         <p>{chapter.name}</p>
//       </div>
//       {isLocked ? (
//         <div className="ChapterCard-right">
//           <button onClick={() => handleUnlockClick('chapter')}>Unlock chapter</button> {/* Unlock only the chapter */}
//           <button onClick={() => handleUnlockClick('course')}>Unlock course</button> {/* Unlock whole course */}
//           {/* <img src={lockIcon} alt="lock-icon" className="lock-icon" /> */}
//         </div>
//       ) : (
//         <div className="ChapterCard-right">
//           <div onClick={() => handleOpen('notes')} className="read-watch-button">
//             <span>Read notes</span>
//             <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
//           </div>
//           <div onClick={() => handleOpen('lectures')} className="read-watch-button">
//             <span>Watch lecture</span>
//             <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ChapterCard;






// import React, { useState } from 'react';
// import ReadWatchModal from './ReadWatchModal';
// import UnlockChapterModal from './UnlockChapterModal';
// import LoginModal from './LoginModal';
// import nextIcon from '../images/ic_arrow_right.svg';
// import '../styles/ChapterCard.css';

// const ChapterCard = ({ isLocked, chapter, course ,isAuthenticated }) => {
//   const [notesModal, setNotesModal] = useState(false);
//   const [lecturesModal, setLecturesModal] = useState(false);
//   const [unlockChapterModal, setUnlockChapterModal] = useState(false);
//   const [loginModal, setLoginModal] = useState(false);
//   const [unlockType, setUnlockType] = useState('');

//   // Open the appropriate modal based on user action
//   const handleOpen = (type) => {
//     if (type === 'notes') {
//       setNotesModal(true);
//     } else if (type === 'lectures') {
//       setLecturesModal(true);
//     } else if (type === 'login') {
//       setLoginModal(true);
//     } else {
//       setUnlockChapterModal(true);
//     }
//   };

//   // Handle unlocking logic
//   const handleUnlockClick = (unlockOption) => {
//     setUnlockType(unlockOption);
//     handleOpen('unlockChapter');
//   };

//   return (
//     <div className="ChapterCard">
//       {notesModal && (
//         <ReadWatchModal
//           type={'notes'}
//           handleClose={() => setNotesModal(false)}
//           chapter={chapter}
//         />
//       )}
//       {lecturesModal && (
//         <ReadWatchModal
//           type={'lectures'}
//           handleClose={() => setLecturesModal(false)}
//           chapter={chapter}
//         />
//       )}
//       {unlockChapterModal && (
//         <UnlockChapterModal
//           handleClose={() => setUnlockChapterModal(false)}
//           course={course}
//           chapter={chapter}
//           unlockType={unlockType}
//         />
//       )}
//       {!isAuthenticated&&loginModal && <LoginModal setLoginModal={() => setLoginModal(false)} />}

//       <div className="ChapterCard-left">
//         <span>Chapter {chapter.number}</span>
//         <p>{chapter.name}</p>
//       </div>

//       {isLocked ? (
//         <div className="ChapterCard-right">
//           <button onClick={() => handleUnlockClick('chapter')}>Unlock chapter</button>
//           <button onClick={() => handleUnlockClick('course')}>Unlock course</button>
//         </div>
//       ) : (
//         <div className="ChapterCard-right">
//           <div onClick={() => handleOpen('notes')} className="read-watch-button">
//             <span>Read notes</span>
//             <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
//           </div>
//           <div onClick={() => handleOpen('lectures')} className="read-watch-button">
//             <span>Watch lecture</span>
//             <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ChapterCard;





// import React, { useState } from 'react';
// import ReadWatchModal from './ReadWatchModal';
// import UnlockChapterModal from './UnlockChapterModal';
// import LoginModal from './LoginModal';
// import nextIcon from '../images/ic_arrow_right.svg';
// import '../styles/ChapterCard.css';

// const ChapterCard = ({ isLocked, chapter, course, isAuthenticated }) => {
//   const [notesModal, setNotesModal] = useState(false);
//   const [lecturesModal, setLecturesModal] = useState(false);
//   const [unlockChapterModal, setUnlockChapterModal] = useState(false);
//   const [loginModal, setLoginModal] = useState(false);
//   const [unlockType, setUnlockType] = useState('');

//   // Open the appropriate modal based on user action
//   const handleOpen = (type) => {
//     if (type === 'notes') {
//       if (!isAuthenticated) {
//         // Show login modal if user is not authenticated
//         setLoginModal(true);
//       } else {
//         setNotesModal(true);
//       }
//     } else if (type === 'lectures') {
//       if (!isAuthenticated) {
//         setLoginModal(true);
//       } else {
//         setLecturesModal(true);
//       }
//     } else if (type === 'login') {
//       setLoginModal(true);
//     } else {
//       setUnlockChapterModal(true);
//     }
//   };

//   // Handle unlocking logic
//   const handleUnlockClick = (unlockOption) => {
//     if (!isAuthenticated) {
//       setLoginModal(true);
//     } else {
//       setUnlockType(unlockOption);
//       handleOpen('unlockChapter');
//     }
//   };


  
//   const handleClose = () => {
//     setNotesModal(false);
//    setLecturesModal(false);
//    setUnlockChapterModal(false);
//     setLoginModal(false);
//  };

//   return (
//     <div className="ChapterCard">
//       {notesModal && (
//         <ReadWatchModal
//           type={'notes'}
//           handleClose={handleClose}
//           chapter={chapter}
//         />
//       )}
//       {lecturesModal && (
//         <ReadWatchModal
//           type={'lectures'}
//           handleClose={handleClose}
//           chapter={chapter}
//         />
//       )}
//       {unlockChapterModal && (
//         <UnlockChapterModal
//           handleClose={handleClose}
//           course={course}
//           chapter={chapter}
//           unlockType={unlockType}
//         />
//       )}
     
//       {loginModal && (
//         <LoginModal handleClose={handleClose} setLoginModal={setLoginModal} />
//       )}
      
//       <div className="ChapterCard-left">
//         <span>Chapter {chapter.number}</span>
//         <p>{chapter.name}</p>
//       </div>

//       {isLocked ? (
//         <div className="ChapterCard-right">
//           <button onClick={() => handleUnlockClick('chapter')}>Unlock chapter</button>
//           <button onClick={() => handleUnlockClick('course')}>Unlock course</button>
//         </div>
//       ) : (
//         <div className="ChapterCard-right">
//           <div onClick={() => handleOpen('notes')} className="read-watch-button">
//             <span>Read notes</span>
//             <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
//           </div>
//           <div onClick={() => handleOpen('lectures')} className="read-watch-button">
//             <span>Watch lecture</span>
//             <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ChapterCard;





import React, { useState } from 'react';
import ReadWatchModal from './ReadWatchModal';
import UnlockChapterModal from './UnlockChapterModal';
import LoginModal from './LoginModal';
import ReadNotesModal from './ReadNotesModal'; 
import lockIcon from '../images/ic_lock.svg';
import nextIcon from '../images/ic_arrow_right.svg';
import '../styles/ChapterCard.css';
import RegistrationFeeModal from './RegistrationFeeModal';

const ChapterCard = ({ isLocked, chapter, course, isAuthenticated ,isRegistrationFeesPaid}) => {
  const [notesModal, setNotesModal] = useState(false);
  const [lecturesModal, setLecturesModal] = useState(false);
  const [unlockChapterModal, setUnlockChapterModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [unlockType, setUnlockType] = useState('');
  const [registrationFeeModal, setRegistrationFeeModal] = useState(false);


  // Open the appropriate modal based on user action
  const handleOpen = (type) => {
    if (type === 'notes') {
      if (!isAuthenticated) {
        // Show login modal if user is not authenticated
        setLoginModal(true);
      } else {
        setNotesModal(true);
      }
    } else if (type === 'lectures') {
      if (!isAuthenticated) {
        setLoginModal(true);
      } else {
        setLecturesModal(true);
      }
    } else if (type === 'login') {
      setLoginModal(true);
    } else {
      setUnlockChapterModal(true);
    }
  };

  // Handle unlocking logic
  const handleUnlockClick = (unlockOption) => {
    if (!isAuthenticated) {
      setLoginModal(true);
    } else if (!isRegistrationFeesPaid) {
      // Open registration fees modal if user hasn't paid
      setRegistrationFeeModal(true); // This modal should handle the fee payment process
    } else {
      setUnlockType(unlockOption);
      handleOpen('unlockChapter');
    }
  };
  
  const handlePaymentSuccess = () => {
console.log('first') // Dispatch action to update the payment status in Redux
  };

  
  const handleClose = () => {
    setNotesModal(false);
   setLecturesModal(false);
   setUnlockChapterModal(false);
    setLoginModal(false);
    setRegistrationFeeModal(false); 
 };

  return (
    <div className="ChapterCard">
      {notesModal &&
        <ReadNotesModal
          handleClose={handleClose}
          chapter={chapter}
        />
      }
      {lecturesModal && (
        <ReadWatchModal
          type={'lectures'}
          handleClose={handleClose}
          chapter={chapter}
        />
      )}

{registrationFeeModal && (
      <RegistrationFeeModal
        handleClose={() => setRegistrationFeeModal(false)}
        onPaymentSuccess={handlePaymentSuccess}
      />
    )}
      {unlockChapterModal && (
        <UnlockChapterModal
          handleClose={handleClose}
          course={course}
          chapter={chapter}
          unlockType={unlockType}
        />
      )}
    
     
      {loginModal && (
        <LoginModal handleClose={handleClose} setLoginModal={setLoginModal} />
      )}
      
      <div className="ChapterCard-left">
        <span>Chapter {chapter.number}</span>
        <p>{chapter.name}</p>
      </div>

      {isLocked ? (
        <div className="ChapterCard-right">
          <button onClick={() => handleUnlockClick('chapter')}>Unlock chapter</button>
          <button onClick={() => handleUnlockClick('course')}>Unlock course</button>
        </div>
      ) : (
        <div className="ChapterCard-right">
          <div onClick={() => handleOpen('notes')} className="read-watch-button">
            <span>Read notes</span>
            <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
          </div>
          <div onClick={() => handleOpen('lectures')} className="read-watch-button">
            <span>Watch lecture</span>
            <img src={nextIcon} alt="next-icon" className="unlocked-icons" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChapterCard;
