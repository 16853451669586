import React,{useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@material-ui/core';
import {Box} from '@material-ui/core';
import { useDispatch,useSelector } from 'react-redux';
import { userRankings } from '../../redux/actions/getStudentActions';


export  const  ParentDropdown=()=> {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box
    component="form"
    sx={{
      '& .MuiTextField-root': { width: '25ch' },
    }}
    noValidate
    autoComplete="off"
  >
    <div>
      <TextField
         id="outlined-size-small"
        select
        defaultValue="All Subjects"
        // helperText="Please select subject"
        size="small"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Physics</MenuItem>
        <MenuItem value={20}>Chemistry</MenuItem>
        <MenuItem value={30}>Maths</MenuItem>
        <MenuItem value={30}>Biology</MenuItem>
    

      
      </TextField>
      </div>
     </Box>
  );
}




export const  LiveClassesDropdown=()=> {
    const [age, setAge] = React.useState('');
  
    const handleChange = (event) => {
      setAge(event.target.value);
    };
  
    return (
      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
           id="outlined-size-small"
          select
          defaultValue="All Subjects"
          // helperText="Please select subject"
          size="small"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Physics</MenuItem>
          <MenuItem value={20}>Chemistry</MenuItem>
          <MenuItem value={30}>Maths</MenuItem>
          <MenuItem value={30}>Biology</MenuItem>
      
  
        
        </TextField>
        </div>
       </Box>
    );
  }
  


  
export const  MonthlyDropdown=({setFilteredReports})=> {



  const handleChange = (event) => {
    setFilteredReports(event.target.value);
  };

  return (
    <Box
    component="form"
    sx={{
      '& .MuiTextField-root': { width: '25ch' },
    }}
    noValidate
    autoComplete="off"
  >
    <div>
      <TextField
         id="outlined-size-small"
        select
        defaultValue="All Subjects"
        // helperText="Please select subject"
        size="small"
       onChange={handleChange}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        <MenuItem value='MONTHLY'>Monthly</MenuItem>
        <MenuItem value='YEARLY'>Yearly</MenuItem>
      
    

      
      </TextField>
      </div>
     </Box>
  );
}




export  const  RankingDropDown=()=> {
  const [val, setVal] = React.useState('');
  const dispatch=useDispatch();
  const {allExams}=useSelector((store)=>store.studentReducer)

 


  const handleChange = (event) => {
    setVal(event.target.value);
  };


  const latestExams=allExams?.filter((item)=>item?.testType===val)


 const lastExamId=latestExams?.reverse()[0]

 console.log('lastExamId', lastExamId)

useEffect(()=>{
  dispatch(userRankings(lastExamId))
  },[val])
 
  

  return (
    <Box
    component="form"
    sx={{
      '& .MuiTextField-root': { width: '25ch' },
    }}
    noValidate
    autoComplete="off"
  >
    <div>
      <TextField
         id="outlined-size-small"
        select
        defaultValue="mcq"
        // helperText="Please select subject"
        size="small"
        onChange={handleChange}
      >
       
        <MenuItem value={'mcq'} >MCQ Test</MenuItem>
        <MenuItem value={'subject_wise'} >Subjective Test</MenuItem>
        <MenuItem value={'mega_test'} >Mega Test</MenuItem>
  
    

      
      </TextField>
      </div>
     </Box>
  );
}



  
export default ParentDropdown;