
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getUserPoints } from '../redux/actions/userActions';
import ecLogo from '../images/ec_logo_square.jpg';
import { verifyRegistrationPayment, purchaseRegistration } from '../redux/actions/api/index';
import { setUserDetails } from '../redux/actions/userActions';
import boyImage from '../images/boy1.jpg';
import iconImage from '../images/picon.png';
import girlImage from '../images/girl1.png'; // Assuming girlImage is correctly imported

const RegistrationFeeModal = ({ handleClose }) => {
 
  const [hover, setHover] = useState(false); // Hover state added
  const navigate = useNavigate();
  const { userPoints, userData } = useSelector((store) => store.userReducer);
  const { allStandards } = useSelector((store) => store.standardReducer);
  const standardData = allStandards?.find((item) => item.class === userData.standard);
  const [showRewardSelection, setShowRewardSelection] = useState(false);
  const [finalPrice, setFinalPrice] = useState(standardData.registrationFees);
  const [selectedReward, setSelectedReward] = useState(null);
  const [pointsUsed, setPointsUsed] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserPoints());
  }, []);

  const handleBuyNow = async (selectedReward, amount) => {
    try {
      const response = await purchaseRegistration(userData.id, {
        amount,
        rewardType: selectedReward,
        pointsUsed: pointsUsed,
      });

      if (response.status !== 201) throw new Error('Purchase failed');

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: response.data.response.amount,
        currency: response.data.response.currency,
        name: 'Enrouting Careers',
        description: response.data.response.notes.desc,
        image: ecLogo,
        order_id: response.data.response.id,
        handler: async (paymentResponse) => {
          try {
            const paymentDetails = {
              orderCreationId: response.data.response.id,
              razorpayPaymentId: paymentResponse.razorpay_payment_id,
              razorpaySignature: paymentResponse.razorpay_signature,
              userId: userData.id,
              amount,
              rewardType: selectedReward,
              pointsUsed: pointsUsed,
            };

            const verifyResponse = await verifyRegistrationPayment(paymentDetails);
            if (verifyResponse.status === 200) {
              dispatch(setUserDetails());
              navigate('/subscription', {
                state: {
                  item: { userData, paymentDetails, type: 'registrationFees' },
                },
              });
            } else {
              throw new Error('Payment verification failed');
            }
          } catch (error) {
            console.error('Error verifying payment:', error);
            toast.error('Payment verification failed');
          }

          handleClose();
          document.body.style.overflow = 'auto';
        },
        prefill: {
          name: `${userData.firstname} ${userData.lastname}`,
          email: userData.email,
          contact: userData.phone,
        },
        theme: { color: '#3399cc' },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on('payment.failed', () => {
        toast.error('Payment failed');
        handleClose();
        document.body.style.overflow = 'auto';
      });
    } catch (error) {
      console.error('Error during purchase:', error);
      toast.error(error.message || 'An error occurred during the purchase');
    } finally {
      document.body.style.overflow = 'auto';
    }
  };

  const applyReward = (rewardType) => {
    const rewardAmount = rewardType === 'welcomeBonus' ? Math.floor(userPoints?.data?.welcomeBonus) : Math.floor(userPoints?.data?.loyaltyPoints);

    const discount = Math.min(standardData.registrationFees - 1, rewardAmount);
    setPointsUsed(discount);
    setSelectedReward(rewardType);
    setFinalPrice(standardData.registrationFees - discount);
    setShowRewardSelection(false);
  };

  const removeReward = () => {
    setSelectedReward(null);
    setPointsUsed(0);
    setFinalPrice(standardData?.registrationFees);
  };

  const RewardSelection = () => (
    <div className="reward-selection-modal">
      <img
        src={boyImage}
        alt="anime"
        style={{ height: '200px', width: 'auto', marginRight: '15px' }}
        className="circular-image"
      />
      <div></div>
      <h2>Select a reward to apply</h2>
      <div className="reward-buttons">
        <button
          disabled={userPoints?.data?.welcomeBonus === 0}
          onClick={() => applyReward('welcomeBonus')}
          className="use-welcome-bonus-btn"
        >
          Use Welcome Bonus (₹{Math.floor(userPoints?.data?.welcomeBonus)})
        </button>

        <button className="styled-button" onClick={() => setShowRewardSelection(false)}>
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div className="UnlockChapterModal-content">
      {showRewardSelection ? (
        <RewardSelection />
      ) : (
        <div className="UnlockChapterModal-content">
          <div className="pay-registration-fee">Pay Registration Fee</div>
          <p className="pay-registration-fee-description">
            To unlock chapters, please pay the registration fee.
            <br />
            Price: ₹{finalPrice}
          </p>

          <div className="modal-buttons">
            <button
              className="styled-button"
              onMouseEnter={() => setHover(true)} // Mouse enter sets hover to true
              
              onMouseLeave={() => setHover(false)} // Mouse leave sets hover to false
              onClick={() => setShowRewardSelection(true)}
            >
              {hover ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={girlImage}
                    alt="hover effect"
                    style={{ height: '100px', width: 'auto', marginRight: '-70px' }}
                  />
                  <div>
                    <p className="congrats-text">
                      Congratulations! You have WON {userPoints?.data?.welcomeBonus} welcome points!
                    </p>
                    <p className="points-description">
                      <img
                        src={iconImage}
                        alt="icon"
                        style={{ height: '15px', width: 'auto', marginRight: '5px' }}
                      />
                      Use your points to avail waiver on the purchase of pre-recorded sessions.
                      <br />
                      <img
                        src={iconImage}
                        alt="icon"
                        style={{ height: '15px', width: 'auto', marginRight: '5px' }}
                      />
                      These points are valid for 40 days from the date of first purchase.
                      <br />
                      <img
                        src={iconImage}
                        alt="icon"
                        style={{ height: '15px', width: 'auto', marginRight: '5px' }}
                      />
                      Points cannot be redeemed as cash or combined with other discounts.
                      <br />
                      <img
                        src={iconImage}
                        alt="icon"
                        style={{ height: '15px', width: 'auto', marginRight: '5px' }}
                      />
                      Points are non-transferable.
                    </p>
                  </div>
                </div>
              ) : (
                'Use Points'
              )}
            </button>
            <button className="styled-button-right" onClick={() => handleBuyNow(selectedReward, finalPrice)}>
              Pay Now
            </button>
            <button className="styled-button-right" onClick={handleClose}>
              Close
            </button>
          </div>

          {selectedReward && (
            <div>
              <p>
                <strong>
                  {selectedReward === 'welcomeBonus' ? 'Welcome Bonus' : 'Loyalty Points'} applied!
                </strong>
                <button className="styled-button" onClick={removeReward}>
                  Remove
                </button>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RegistrationFeeModal;
