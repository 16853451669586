import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Navbar from '../Navbar';
import { Box, Container, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { GrView } from "react-icons/gr";
import ParentDropdown from '../ParentDropdown';
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { checkExamAttendance } from '../../../redux/actions/getStudentActions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function TestSubjective() {
  const dispatch = useDispatch();
  const { allExams, selectedStudent, examAttendance } = useSelector((store) => store.studentReducer);

  const filteredSubjectiveExams = allExams?.filter(
    (item) => item?.select_class === selectedStudent?.class?.toString() && item?.testType === 'subject_wise'
  );

  useEffect(() => {
    if (selectedStudent) {
      filteredSubjectiveExams.forEach((exam) => {
        dispatch(checkExamAttendance(selectedStudent.id, exam.id));
      });
    }
  }, [selectedStudent]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className='slate'>
      <Navbar />

      <Container sx={{ mt: 10 }}>
        <Grid container spacing={4} alignItems="center" justifyContent="center" my={4}>
          <Grid item xs={12} md="auto">
            <Typography variant="h4" component="h2" color="black" textAlign={isMobile ? 'center' : 'left'}>
              Subjective Exam - All Subjects
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            {/* Center the dropdown on mobile */}
            <Box display="flex" justifyContent={isMobile ? 'center' : 'flex-end'} width="100%">
              <ParentDropdown fullWidth={isMobile} />
            </Box>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Date <FaCaretDown /></StyledTableCell>
                <StyledTableCell align="center">Subject</StyledTableCell>
                <StyledTableCell align="center">Chapter</StyledTableCell>
                <StyledTableCell align="center">Total Marks</StyledTableCell>
                <StyledTableCell align="center">View Marksheet</StyledTableCell>
                <StyledTableCell align="center">View Answer Key</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSubjectiveExams?.map((exam) => {
                const attendanceData = examAttendance?.attended?.find((index) => index.examId === exam.id);

                return (
                  <StyledTableRow key={exam?.id}>
                    <StyledTableCell>{exam?.select_date}</StyledTableCell>
                    <StyledTableCell align='center'>{exam?.select_subject}</StyledTableCell>
                    <StyledTableCell align="center">{exam?.select_chapter}</StyledTableCell>
                    <StyledTableCell align="center">{exam?.total_marks}</StyledTableCell>
                    <StyledTableCell align="center">
                      {attendanceData ? <GrView /> : 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {attendanceData ? <GrView /> : 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <span className={`badge ${attendanceData && attendanceData.attendedBy ? 'badge-success' : 'badge-danger'}`}>
                        {attendanceData && attendanceData.attendedBy ? 'Attended' : 'Not attended'}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}
