import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './Navbar';
import Footer from './Footer';
import tickIcon from '../images/ic_success_tick.svg';
import bgImage from '../images/img_bg.png';
import notesImage from '../images/ic_notes_unlocked.svg';
import downloadIcon from '../images/ic_download.svg';
import Receipt from './Receipt';
import { jsPDF } from 'jspdf';
import ReactDOMServer from 'react-dom/server';
import '../styles/SubscriptionPage.css';
import { getUserChapters, getUserCourses } from '../redux/actions/courseActions';

const SubscriptionPage = () => {
  const dispatch=useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { type,  event, workshop, paymentDetails, regType,item } =
    location.state;
console.log('ocation', location)
console.log("item",item)
  const [courseDetails, setCourseDetails] = useState({
    amount: '',
    paymentType: '',
    discounted_amount:''
  });

  const [boolVal, setBoolVal] = useState(false);

  const { id, firstname, lastname, email, phone, address, state } = useSelector((store) => store.userReducer?.userData);
  const { userCourses ,userChapters} = useSelector((store) => store.courseReducer);


  console.log('userChapters', userChapters)
  useEffect(() => {
    if (type === 'course') {
      dispatch(getUserCourses());
    } if (type === 'chapter') {
      dispatch(getUserChapters());
    }
  }, []);
  // console.log(course, paymentDetails);

  const handleDownloadInvoice = () => {
    const inNumber = `0000560/${new Date().getDate().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}${(new Date().getMonth() + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}${new Date().getFullYear()}/${id}`;
    var billData = {
      userName: firstname + ' ' + lastname,
      address: address,
      state: state,
      phone: phone,
      email: email,
    };
    if (type === 'event') {
      console.log('event', event);
      billData = {
        ...billData,
        invoiceNumber: `${inNumber}/${event.id}`,
        issueData: new Date(),
        description: `Event - ${event.topic} ${
          regType === 'solo' ? '(solo purchase)' : '(group purchase)'
        }`,
        amount: regType === 'solo' ? event.soloPrice : event.groupPrice,
      };
    }
    if (type === 'course') {
      console.log('course', item);
      billData = {
        ...billData,
        invoiceNumber: `${inNumber}/${item.id}`,
        issueData: new Date(),
        description: `Course - (${item.subject}, ${item.class}th standard ${item.board})`,
        amount: courseDetails.amount,
      };
    }

    if (type === 'chapter') {
      console.log('chapter', item);
      billData = {
        ...billData,
        invoiceNumber: `${inNumber}/${item.courseId}/${item.id}`,
        issueData: new Date(),
        description: `Chapter - (${item.name})`,
        amount: courseDetails.amount,

      };
    }

    if (type === 'workshop') {
      console.log('workshop', workshop);
      billData = {
        ...billData,
        invoiceNumber: `${inNumber}/${workshop.id}`,
        issueData: new Date(),
        description: `Workshop - Topic: (${workshop.topic})`,
        amount: workshop.price,
      };
    }

    var doc = new jsPDF('p', 'pt', 'a4');
    doc.html(
      ReactDOMServer.renderToStaticMarkup(<Receipt billData={billData} />),
      {
        callback: function (pdf) {
          var pageCount = doc.internal.getNumberOfPages();
          pdf.deletePage(pageCount);
          pdf.save('invoice.pdf');
        },
      }
    );
  };
  useEffect(() => {
    if (type === 'course' && !boolVal) {
      const findCourseDetails = () => {
        if (paymentDetails.typeofSubcription === 'Yearly') {
          setCourseDetails({ amount: item.yearlyPrice, paymentType: ' / year' });
        } else if (paymentDetails.typeofSubcription === 'Quarterly') {
          setCourseDetails({ amount: item.quarterlyPrice, paymentType: ' for 3 months' });
        } else {
          setCourseDetails({ amount: item.monthlyPrice, paymentType: ' for a month' });
        }
      };
      findCourseDetails();
      setBoolVal(true);
    }
  }, [type, boolVal, item, paymentDetails]);
  
  useEffect(() => {
    if (type === 'chapter' && !boolVal) {
      const findChapterDetails = () => {
        if (paymentDetails.subscriptionType === 'Yearly') {
          setCourseDetails({ amount: item.ch_yearly_price, paymentType: ' / year' });
        } else if (paymentDetails.subscriptionType === 'Quarterly') {
          setCourseDetails({ amount: item.ch_quarterly_price, paymentType: ' for 3 months' });
        } else {
          setCourseDetails({ amount: item.ch_monthly_price, paymentType: ' for a month' });
        }
      };
      findChapterDetails();
      setBoolVal(true);
    }
  }, [type, boolVal, item, paymentDetails]);
  

  const getFormattedDate = () => {
    const dateObj = new Date();
    const date = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const year = dateObj.getFullYear();
    return date + ' ' + month + ', ' + year;
  };

  const getConvertedDate = (str) => {
    const newStr = str.substring(0, str.length - 8);
    return newStr;
  };

  return (
    <div className='SubscriptionPage'>
      <Navbar />
      <div className='SubscriptionPage-title-container'>
        <div className='SubscriptionPage-content'>
          <img src={bgImage} alt='bg-img' className='bg-img' />
          <img src={tickIcon} alt='tick-icon' className='tick-icon' />
          {type === 'course' && (
            <h1>Thank you for purchasing your subscription</h1>
          )}
          {type === 'event' && <h1>Thank you for registering for events</h1>}
          {type === 'workshop' && (
            <h1>Thank you for registering for workshops</h1>
          )}
          <p>We hope you enjoy the premium experience of enrouting carrier.</p>
          <p>Happy learning.</p>
        </div>
      </div>
      <div className='subscription-card-container'>
        <div className='subscription-card'>
          <p className='subscription-card-title'>
            {type === 'course' && 'Subscription details'}
            {type === 'event' && 'Event details'}
            {type === 'workshop' && 'Workshop details'}
          </p>
          <div className='subscription-details-container'>
            {type === 'course' && (
              <div className='subscription-details-container-col-left'>
                <p>Date of purchase</p>
                <p>Amount paid</p>
                <p>Subject</p>
                <p>Standard / Class</p>
                <p>Board</p>
              </div>
            )}

              {type === 'chapter' && (
              <div className='subscription-details-container-col-left'>
                <p>Date of purchase</p>
                <p>Amount paid</p>
                <p>Subject</p>
                <p>Standard / Class</p>
                <p>Board</p>
              </div>
            )}
            {type === 'event' && (
              <div className='subscription-details-container-col-left'>
                <p>Date of register</p>
                <p>Amount paid</p>
                <p>Event name</p>
                <p>Start date</p>
                <p>End date</p>
              </div>
            )}
            {type === 'workshop' && (
              <div className='subscription-details-container-col-left'>
                <p>Date of register</p>
                <p>Amount paid</p>
                <p>Workshop name</p>
                <p>Date</p>
              </div>
            )}
            {type === 'course' && (
              <div className='subscription-details-container-col-right'>
                <p>{getFormattedDate()}</p>
                <p>
                  ₹{courseDetails.amount} {courseDetails.paymentType}
                </p>
                <p>{item.subject}</p>
                <p>{item.class}th standard</p>
                <p>{item.board}</p>
              </div>
            )}

              {type === 'chapter' && (
              <div className='subscription-details-container-col-right'>
                <p>{getFormattedDate()}</p>
                <p>
                  ₹{courseDetails.amount} {courseDetails.paymentType}
                </p>
                <p>{item.subject}</p>
                <p>{item.class}th standard</p>
                <p>{item.board}</p>
              </div>
            )}
            {type === 'event' && (
              <div className='subscription-details-container-col-right'>
                <p>{getFormattedDate()}</p>
                <p>
                  ₹{regType === 'solo' ? event.soloPrice : event.groupPrice}
                </p>
                <p>{event.topic}</p>
                <p>{getConvertedDate(event.start_time)}</p>
                <p>{getConvertedDate(event.end_time)}</p>
              </div>
            )}
            {type === 'workshop' && (
              <div className='subscription-details-container-col-right'>
                <p>{getFormattedDate()}</p>
                <p>₹{workshop.price}</p>
                <p>{workshop.topic}</p>
                <p>{getConvertedDate(workshop.start_date)}</p>
              </div>
            )}
          </div>
          <div className='invoice-container' onClick={handleDownloadInvoice}>
            <img
              src={downloadIcon}
              alt='download-icon'
              className='download-icon'
            />
            <span>Download invoice</span>
          </div>
        </div>
        {type === 'course' && (
          <div className='subscription-card'>
            <p className='subscription-card-title'>Subscribed notes</p>
            <p className='subscription-card-text'>
              We have added {item.subject} notes to your account you can check
              in my account / my notes section.
            </p>
            <img src={notesImage} alt='notes-img' className='notes-img' />
            <div>
              <button
                className='subscription-card-button'
                onClick={() =>
                  history('/notes', {state: 'notes' })
                }
              >
                My notes
              </button>
            </div>
          </div>
        )}

{type === 'chapter' && (
          <div className='subscription-card'>
            <p className='subscription-card-title'>Subscribed notes</p>
            <p className='subscription-card-text'>
              We have added {item.subject} notes to your account you can check
              in my account / my notes section.
            </p>
            <img src={notesImage} alt='notes-img' className='notes-img' />
            <div>
              <button
                className='subscription-card-button'
                onClick={() =>
                  history('/notes', {state: 'notes' })
                }
              >
                My notes
              </button>
            </div>
          </div>
        )}
        {type === 'event' && (
          <div className='subscription-card'>
            <p className='subscription-card-title'>All events</p>
            <p className='subscription-card-text'>
              We have added event to your account you can check in my account /
              Registered events section.
            </p>
            <img src={notesImage} alt='notes-img' className='notes-img' />
            <div>
              <button
                className='subscription-card-button'
                onClick={() => history('/myEvents')}
              >
                Registered events
              </button>
            </div>
          </div>
        )}
        {type === 'workshop' && (
          <div className='subscription-card'>
            <p className='subscription-card-title'>All workshops</p>
            <p className='subscription-card-text'>
              We have added workshop to your account you can check in my account
              / Registered workshops section.
            </p>
            <img src={notesImage} alt='notes-img' className='notes-img' />
            <div>
              <button
                className='subscription-card-button'
                onClick={() => history('/myWorkshops')}
              >
                Registered workshops
              </button>
            </div>
          </div>
        )}
      </div>
      <div className='workshop-title'>
        <h1>Best technical workshops to attend</h1>
      </div>
      <div className='best-workshop-card-container'>
        <div className='best-workshop-card'>
          <div className='best-workshop-card-img'></div>
          <h4>Career in automobile Engineering</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliquat un
            muter...
          </p>
          <span>Know more</span>
        </div>
        <div className='best-workshop-card'>
          <div className='best-workshop-card-img'></div>
          <h4>Career in mechanical Engineering</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliquat un
            muter...
          </p>
          <span>Know more</span>
        </div>
        <div className='best-workshop-card'>
          <div className='best-workshop-card-img'></div>
          <h4>Career as a chartered accountant</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliquat un
            muter...
          </p>
          <span>Know more</span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionPage;
