import React, { useState, useEffect } from "react";
import maleTeacher from "../images/front-view-man-classroom.jpg";
import LoginModal from "./LoginModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getMeetings } from "../redux/actions/meetingActions";
import { useDispatch } from "react-redux";
import { formatDate } from "../functions/date";

export default function DummyCards() {
  const dispatch = useDispatch();
  const { meetings } = useSelector((store) => store.meetingReducer);
  const [loginModel, setLoginModal] = useState(null);
  useEffect(() => {
    if (!meetings) {
      // Check if meetings is null or undefined
      dispatch(getMeetings());
    }
  }, [dispatch, meetings]);
  const meet = meetings?.data?.meeting
    ? [...meetings.data.meeting].reverse()
    : [];
  const meeetingData = meet;
  console.log("meeting", meeetingData);

  const handleClick = () => {
    setLoginModal(true);
    toast.info("Please login to move further");
  };

  const date = new Date();
  const formattedDate = formatDate(date);

  return (
    <div className="row ">
      {loginModel && (
        <div>
          <LoginModal setLoginModal={() => setLoginModal(false)} />{" "}
        </div>
      )}

      {meeetingData?.map((item, index) => (
        <div className="col-lg-4 col-md-6 ">
          <div
            className="custom-card m-2"
            style={{ height: "10rem" }}
            key={index}
          >
            <div className="custom-flex">
              <div className="custom-image">
                <img src={maleTeacher} className="custom-img" alt="Card" />
              </div>
              <div className="custom-body">
                <div className="custom-date"> {formattedDate} | 59mins </div>
                <h5 className="custom-title">
                  {item?.chapter?.length >= 15
                    ? item.chapter.slice(0, 18) + ".."
                    : item.chapter}{" "}
                  | {item?.subject}
                </h5>
                <p className="custom-text">{item?.createdBy}</p>
                <p className="custom-class">{item?.class}th </p>

                <button
                  onClick={handleClick}
                  style={{
                    display: "flex",
                    "background-color": "#180882",
                    color: "#fff",
                    "border-radius": "20px",
                    "text-decoration": "none",

                    height: "2rem",
                    "align-items": "center",
                    "justify-content": "center" /* Horizontally centering */,
                    "text-align": "center",
                    width: "8rem",
                  }}
                >
                  JOIN
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
