import React, { useState, useEffect } from "react";
import TeacherNavbar from "./Teacher/TeacherNavbar";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Stack,
  TextField,
  Grid,
  MenuItem,
  Popover,
} from "@mui/material";
import BreadCrumbs from "./BreadCrumbs";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import AddUserModal from "./addUserModal/addUserModal";
import {
  createBulkUser,
  getAllPendingUsers,
} from "../redux/actions/bulkUserActions";
import { getAllUsersByTeacher } from "../redux/actions/bulkUserActions";
import { toast } from "react-toastify";

export default function BulkUserPage() {
  const dispatch = useDispatch();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { legitUserData, pendingData } = useSelector(
    (store) => store.bulkUserReducer
  );
  const [selectedFormData, setSelectedFormData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // For Popover
  const [selectedFile, setSelectedFile] = useState(null); // Track selected file
  const [uploading, setUploading] = useState(false); // Track loading state
  const [status, setStatus] = useState("");

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  // Open the modal for adding a user
  const handleAddClick = () => {
    setSelectedFormData(null);
    setIsAddOpen(true);
    setIsEdit(false);
  };

  // Open the modal for editing a user
  const handleEditClick = (formData) => {
    setSelectedFormData(formData);
    setIsAddOpen(true);
    setIsEdit(true);
  };

  useEffect(() => {
    if (!legitUserData) {
      dispatch(getAllUsersByTeacher());
      dispatch(getAllPendingUsers());
    }
  }, [dispatch]);

  // Open popover for file upload
  const handleUploadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file); // Set the selected file
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (
      selectedFile &&
      (selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setUploading(true); // Start loading indicator

      const formData = new FormData();
      formData.append("file", selectedFile); // 'file' is the key, which will be received in backend

      try {
        // Dispatch the upload action
         dispatch(createBulkUser(formData)).then(()=>{
          setUploading(false); // Stop loading indicator after upload
          handleClosePopover(); // Close popover on success
          dispatch(getAllUsersByTeacher());
          dispatch(getAllPendingUsers());
          toast.success("File uploaded successfully!"); // Success message
         })
        
      } catch (error) {
        setUploading(false); // Stop loading on error

        // Check for specific error message
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during the upload";
        toast.error(errorMessage); // Display the error message to the user
      }
    } else {
      toast.error("Please select a valid Excel file (.xls or .xlsx)"); // Show error for invalid file format
    }
  };

  // Close the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedFile(null); // Clear selected file on close
  };

  const open = Boolean(anchorEl);

  console.log("legitUserData", legitUserData);
  console.log("pendingData", pendingData);
  const dataToDisplay =
    status === "successful" ? legitUserData?.data : pendingData?.data;

  return (
    <>
      <TeacherNavbar />
      <Box mt={12}>
        <div className="d-flex justify-content-between">
          <Grid item sx={{ paddingLeft: 2, minWidth: 120 }}>
            <TextField
              select
              size="small"
              required
              fullWidth
              label="Status"
              name="status"
              value={status}
              onChange={handleChangeStatus}
            >
              <MenuItem value="successful">Successful</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </TextField>
          </Grid>


            <Button
               size="small"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              Create
            </Button>
           
         
        </div>

        <Box mt={7} mx={2}>
          {uploading && (
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <CircularProgress />
              <p>Uploading... Please wait.</p>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  {status === "successful" && <TableCell>password</TableCell>}
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Standard</TableCell>
                  <TableCell>Section</TableCell>
                  <TableCell>School/College</TableCell>
                  {status !== "successful" && <TableCell>Reason</TableCell>}
                  {status !== "successful" && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataToDisplay?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item.firstname + " " + item.lastname}
                    </TableCell>
                    {status === "successful" && <TableCell>{item?.temp_pass}</TableCell>}
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.phone}</TableCell>
                    <TableCell>{item.standard}</TableCell>
                    <TableCell>{item.section}</TableCell>
                    <TableCell>
                      {item.school ? item.school : item.college}
                    </TableCell>
                    {status !== "successful" && (
                      <TableCell>{item?.validation_errors}</TableCell>
                    )}
                    {status !== "successful" && (
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleEditClick(item)}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {isAddOpen && (
        <AddUserModal
          formData={selectedFormData}
          open={isAddOpen}
          onClose={() => setIsAddOpen(false)}
          isEdit={isEdit}
        />
      )}

      {/* Popover for file upload */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box p={2}>
          <input
            type="file"
            accept=".xls, .xlsx"
            onChange={handleFileChange}
            style={{ display: "block", marginBottom: "10px" }}
          />
          {selectedFile && <p>Selected file: {selectedFile.name}</p>}
          <Button
            variant="contained"
            onClick={handleFileUpload}
            disabled={uploading}
          >
            {uploading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </Popover>
    </>
  );
}
