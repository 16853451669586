import React, { useState, useEffect } from 'react';
import '../styles/TransactionCard.css';

const options = { year: 'numeric', month: 'short', day: '2-digit' };

const TransactionCard = (props) => {
  const {
    course,
    standard,
    subject,
    yearlyPrice,
    monthlyPrice,
    quarterlyPrice,
    date,
    purchaseDetails,
    handleDownloadInvoice
  } = props;

  const parsedDate = Date.parse(date);
  const formattedDate = new Date(parsedDate);

  const [boolVal, setBoolVal] = useState(false);
  const [details, setDetails] = useState({
    OgAmount: '',
    paidAmount: '',
    message: '',
  });

  useEffect(() => {
    if (!boolVal) {
      const subsType = purchaseDetails[purchaseDetails.length - 1].typeofSubcription;

      if (subsType === 'Year') {
        setDetails({
          OgAmount: purchaseDetails[purchaseDetails.length - 1].originalAmount,
          paidAmount: purchaseDetails[purchaseDetails.length - 1].paidAmount,
          message: ' / Year',
        });
      } else if (subsType === 'Quarterly') {
        setDetails({
          OgAmount: purchaseDetails[purchaseDetails.length - 1].originalAmount,
          paidAmount: purchaseDetails[purchaseDetails.length - 1].paidAmount,
          message: ' for 3 months',
        });
      } else {
        setDetails({
          OgAmount: purchaseDetails[purchaseDetails.length - 1].originalAmount,
          paidAmount: purchaseDetails[purchaseDetails.length - 1].paidAmount,
          message: ' for a month',
        });
      }
      setBoolVal(true);
    }
  }, [boolVal, monthlyPrice, yearlyPrice, quarterlyPrice, purchaseDetails]);

  return (
    <div className='TransactionCard'>
      <div className='TransactionCard-row-1'>
        <p className='first-col'>
          Class {standard}th : {subject} notes
        </p>
        <p className='second-col'>
          {/* Showing discount if applicable */}
          ₹{details.paidAmount !== details.OgAmount ? (
            <>
              {details.paidAmount}{' '}
              <span
                style={{
                  textDecorationLine: 'line-through',
                  textDecorationStyle: 'solid',
                  marginLeft: '10px',
                  color: '#999',
                }}
              >
                ₹{details.OgAmount}
              </span>
            </>
          ) : (
            details.paidAmount
          )} {details.message}
        </p>
        <p className='third-col'>
          {formattedDate.toLocaleDateString('en-US', options)}
        </p>
      </div>
      <div className='TransactionCard-row-2'>
        <p className='all-chapters-text'>All chapters</p>
        <p className='download-text'  onClick={() => handleDownloadInvoice('course',course,'receipt')}>Download Receipt</p>
        <p className='download-text'  onClick={() => handleDownloadInvoice('course',course,'invoice')}>Download Invoice</p>
      </div>
    </div>
  );
};

export default TransactionCard;
