import React, { Fragment } from 'react';
import '../styles/TransactionCard.css';

const ProjectTransactionCard = (props) => {
  const { userProject, handleDownloadInvoice } = props;
  return (
    <Fragment>
{userProject?.filter(project => project.orderId !== null).map((project, index) => (
  <div className='TransactionCard' key={index}>
    <div className='TransactionCard-row-1'>
      <p className='first-col'>Project: {`${project?.project?.project_name}`}</p>
      <p className='second-col'>
        ₹{project?.paidAmount !== project?.originalAmount ? (
          <>
            {project.paidAmount}{' '}
            <span
              style={{
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
                marginLeft: '10px',
                color: '#999',
              }}
            >
              ₹{project?.originalAmount}
            </span>
          </>
        ) : (
          project?.paidAmount
        )}
      </p>
      <p className='third-col'>
        {/* You can handle the date format here */}
        {project?.purchasedOn}
      </p>
    </div>
    <div className='TransactionCard-row-2'>
      <p className='all-chapters-text'>
        Project date: {project?.project?.startTime} 
      </p>
      <p
        className='download-text'
        onClick={() => handleDownloadInvoice('project', project,'receipt')}
      >
        Download receipt
      </p>
      <p
        className='download-text'
        onClick={() => handleDownloadInvoice('project', project,'invoice')}
      >
        Download invoice
      </p>
    </div>
  </div>
))}

    </Fragment>
  );
};

export default ProjectTransactionCard;
