import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Navbar from './Navbar';
import { Box, Container, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { MonthlyDropdown } from './ParentDropdown';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(date, subject, chapter, marks, marksheet, answers) {
  return { date, subject, chapter, marks, marksheet, answers };
}

const rows = [
  createData('JANUARY', 'Maths', 'Integration', 24, 'view', 'view'),
  createData('FEBRUARY', 'Maths', 'Integration', 24, 'view', 'view'),
  createData('MARCH', 'Maths', 'Integration', 24, 'view', 'view'),
];

export default function Reports() {
  const [filteredReports, setFilteredReports] = useState('');
  const navigate = useNavigate();
  const { selectedStudent } = useSelector((store) => store.studentReducer);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleViewReport = (id) => {
    navigate(`/parents/reports/${id}`);
  };

  return (
    <div className='slate'>
      <Navbar />

      <Container sx={{ mt: 10 }}>
        {/* Responsive layout for title and dropdown */}
        <Grid container spacing={4} alignItems="center" justifyContent="center" my={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              color="black"
              textAlign={isMobile ? 'center' : 'left'}
              sx={{ mb: isMobile ? 2 : 0 }} // Add margin-bottom on mobile
            >
              Reports - Monthly
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent={isMobile ? 'center' : 'flex-end'}>
              <MonthlyDropdown setFilteredReports={setFilteredReports} fullWidth={isMobile} />
            </Box>
          </Grid>
        </Grid>

        {/* Responsive table */}
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Month</StyledTableCell>
                <StyledTableCell align="center">View</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.date}>
                  <StyledTableCell>{row.date}</StyledTableCell>
                  <StyledTableCell align="center">
                    <button className="badge badge-success" onClick={() => handleViewReport(1)}>
                      View Report
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}
