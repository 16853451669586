
 
 export const ORIGIN = "https://api.enroutingcareers.com";
//export const ORIGIN = "http://localhost:8080";
const BASE_URL = `${ORIGIN}`;
const ZOOM_SDK_KEY='UwiPZGu7QGCDrs7Im38vTw'

const config = {
  BASE_URL,
  ZOOM_SDK_KEY
};

export default config;
