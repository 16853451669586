import { client } from "../../api_client";
import config from "../../../config";
const URL = config.BASE_URL;

const endPoints = {
  addExam: "upload",
  getAllExam: "findAll",
};

const API_URLS = {
  GET_ALL_SUB_MEGA_EXAMS: `${URL}/api/exam/${endPoints.getAllExam}`,
  CREATE_SUB_MEGA_EXAMS: `${URL}/api/exam/${endPoints.addExam}`,
  DELETE_EXAMS: `${URL}/api/exam`,
};

export const getSubMegaExams = async () => {
  try {
    const url = `${API_URLS.GET_ALL_SUB_MEGA_EXAMS}`;
    const response = await client.get(url);

    const data = response.data;

    return data;
  } catch (error) {
    throw new Error("An error occurred while fetching the data.");
  }
};

export const createSubMegaExams = async (postData) => {
  try {
    const url = `${API_URLS.CREATE_SUB_MEGA_EXAMS}`;

    const formData = new FormData();
    formData.append("testType", postData.get("testType"));
    formData.append("select_class", postData.get("select_class"));
    formData.append("questionPaper", postData.get("questionPaper"));
    formData.append("ansKey", postData.get("ansKey"));
    formData.append("select_subject", postData.get("select_subject"));
    formData.append("select_chapter", postData.get("select_chapter"));
    formData.append("start_time", postData.get("start_time"));
    formData.append("end_time", postData.get("end_time"));
    formData.append("select_date", postData.get("select_date"));
    formData.append("total_marks", postData.get("total_marks"));
    formData.append("examName", postData.get("examName"));

    const response = await client.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw new Error("An error occurred while creating the post.");
  }
};

export const deleteExam = async (_id) => {
  try {
    const url = `${API_URLS.DELETE_EXAMS}/${_id}`;
    const response = await client?.delete(url);

    return response.data;
  } catch (error) {
    throw new Error("An error occurred while deleting the post.");
  }
};
