import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import aboutUsImage from '../images/img_aboutus.svg';
import '../styles/OurVisionPage.css';

/*const OurVisionPage = () => {
  var screen = window.innerWidth;
  if (screen > screenSize) {
    return (
      <div className='AboutUsPage'>
        <Navbar />
        <div className='AboutUsPage-container'>
          <div className='AboutUsPage-container-left'>
            <img src={aboutUsImage} alt='aboutUs-img' className='aboutUs-img' />
          </div>
          <div className='AboutUsPage-container-right'>
            <div className='AboutUsPage-text-box'>
              <h1>Vision</h1>
              <ul>
                <li>
                  To provide the best possible practical facilities for training
                  each student towards a bright career.
                </li>
                <li>
                  To provide a creative atmosphere in which high school studies
                  and research thrive both among the students and the faculty.
                </li>
                <li>
                  To organize short intensive courses, conferences, and
                  workshops on current technological developments which will be
                  of benefit to the surrounding community.
                </li>
                <li>
                  To provide research and development consultancy which will
                  promote registering for patents at an early age.
                </li>
                <li>
                  To organize quality improvement programs for students through
                  faculty members from various collegesand various fields.
                </li>
                <li>
                  To provide leadership in curriculum design and development.
                </li>
                <li>
                  Promote the participation of students in different events and
                  workshops to incubate their ideas in the best productive way.{' '}
                </li>
                <li>
                  To encourage students to find solutions to human problems in
                  areas like food, water, healthcare, and energy sector, through
                  scientific, social and technological terms of formulations{' '}
                </li>
              </ul>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='AboutUsPage'>
        <Navbar />
        <div className='AboutUsPage-container'>
          <div className='AboutUsPage-container-left'>
            <h1>Vision</h1>
            <img src={aboutUsImage} alt='aboutUs-img' className='aboutUs-img' />
          </div>
          <div className='AboutUsPage-container-right'>
            <div className='AboutUsPage-text-box'>
              <ul>
                <li>
                  To provide the best possible practical facilities for training
                  each student towards a bright career.
                </li>
                <li>
                  To provide a creative atmosphere in which high school studies
                  and research thrive both among the students and the faculty.
                </li>
                <li>
                  To organize short intensive courses, conferences, and
                  workshops on current technological developments which will be
                  of benefit to the surrounding community.
                </li>
                <li>
                  To provide research and development consultancy which will
                  promote registering for patents at an early age.
                </li>
                <li>
                  To organize quality improvement programs for students through
                  faculty members from various collegesand various fields.
                </li>
                <li>
                  To provide leadership in curriculum design and development.
                </li>
                <li>
                  Promote the participation of students in different events and
                  workshops to incubate their ideas in the best productive way.{' '}
                </li>
                <li>
                  To encourage students to find solutions to human problems in
                  areas like food, water, healthcare, and energy sector, through
                  scientific, social and technological terms of formulations{' '}
                </li>
              </ul>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
};*/
const OurVisionPage = () => {
  return (
    <div className='OurVisionPage'>
      <Navbar activeMenuItem={'vision'} />
      <div className='OurVisionPage-container'>
        <div className='OurVisionPage-container-left'>
          <img src={aboutUsImage} alt='About Us' className='aboutUs-img' />
        </div>
        <div className='OurVisionPage-container-right'>
          <div className='OurVisionPage-text-box'>
            <h1>Vision</h1>
            <ul>
                <li>
                  To provide the best possible practical facilities for training
                  each student towards a bright career.
                </li>
                <li>
                  To provide a creative atmosphere in which high school studies
                  and research thrive both among the students and the faculty.
                </li>
                <li>
                  To organize short intensive courses, conferences, and
                  workshops on current technological developments which will be
                  of benefit to the surrounding community.
                </li>
                <li>
                  To provide research and development consultancy which will
                  promote registering for patents at an early age.
                </li>
                <li>
                  To organize quality improvement programs for students through
                  faculty members from various collegesand various fields.
                </li>
                <li>
                  To provide leadership in curriculum design and development.
                </li>
                <li>
                  Promote the participation of students in different events and
                  workshops to incubate their ideas in the best productive way.{' '}
                </li>
                <li>
                  To encourage students to find solutions to human problems in
                  areas like food, water, healthcare, and energy sector, through
                  scientific, social and technological terms of formulations{' '}
                </li>
              </ul>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};




export default OurVisionPage;
