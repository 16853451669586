import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Container, Box, Typography, useMediaQuery } from "@mui/material";
import TableRank from "./Table";
import { RankingDropDown } from "./ParentDropdown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { userRankings } from "../../redux/actions/getStudentActions";

export const Ranking = () => {
  // Create a theme instance to access breakpoints
  const theme = createTheme();
  const dispatch = useDispatch();

  // Detect if the current screen width is below the 'sm' breakpoint (mobile devices)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={theme}>
      <div className="slate">
        <Navbar />

        <Container sx={{ mt: 10 }}>
          {/* Flex container to hold the title and dropdown */}
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "center" : "space-between"} // Center items on mobile
            alignItems="center"
            my={4}
            sx={{ gap: isMobile ? 2 : 0 }}
          >
            <Typography variant="h4" component="h2" color="black" textAlign={isMobile ? "center" : "left"}>
              Student Ranking
            </Typography>

            {/* Wrap ParentDropdown to control its width */}
            <Box
              sx={{
                width: isMobile ? "100%" : "auto", // Full width on mobile
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-start", // Center the dropdown on mobile
              }}
            >
              <RankingDropDown fullWidth={isMobile} />
            </Box>
          </Box>

          {/* TableRank component */}
          <TableRank />
        </Container>
      </div>
    </ThemeProvider>
  );
};
