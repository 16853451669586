// import React from 'react'
// import TeacherNavbar from './Teacher/TeacherNavbar'
// import { useDispatch, useSelector } from 'react-redux'
// import { IoEyeSharp } from "react-icons/io5";


// export default function RequestLectures() {

// const {requests}=useSelector((store)=>store.requestReducer)
// const Data=requests?.user;
//   return (

//     <div className='HomePage'>
//      <TeacherNavbar/>
// <div className='m-5 p-0'>


// </div>


//  <div className="container m-5 p-0" >
//  <h3>Lecture Requests</h3>
//   <div className='row '>
//   {Data?.map((item,index)=>


// <div className="card  col-md-4 col-lg-5 m-1"key={index}>
// <div className="card-body ">

//   <h5 className="card-title ">Student Name:{item?.studentName}</h5>
  
//   <p className="card-text">Class:{item?.standard}</p>
//   <p className="card-text">Subject:{item?.subjectName}</p>
  
//   <p className="card-text">Chapter:{item?.chapterName}</p>
//   <p className="card-text">Reason:{item?.reason}</p>
//   <p className="card-text">Date:{item?.date}</p>
//   <button  className="btn" style={{backgroundColor:'#edaf38',
// color:"white"}}>Accept</button>
//   <button  className="btn">Reject</button>
//   <button  className="btn flex justify-self-end"><IoEyeSharp/></button>
// </div>
// </div>

// )}
//     </div>
   
//     </div>
  


    
    
//     </div>
  
//   )
// }





import React from 'react'
import TeacherNavbar from './Teacher/TeacherNavbar'
import { useDispatch, useSelector } from 'react-redux'
import { IoEyeSharp } from "react-icons/io5";


export default function RequestLectures() {

const {requests}=useSelector((store)=>store.requestReducer)
const Data=requests?.user;
  return (

    <div className='HomePage'>
     <TeacherNavbar/>
<div className='m-5 p-0'>


</div>


 <div className="container m-5 p-5" >
  <div className='d-flex justify-content-between'>

  <h3>Lecture Requests</h3>
 <button className='btn btn-success'>Download Report</button>
  </div>

 <div className="table-responsive mt-5">
 <table className="table">
  <thead className="thead" style={{'color':'#ffb53e'}}>
    <tr>
      <th scope="col">Sr.No</th>
      <th scope="col">Student Name</th>
      <th scope="col">Subject</th>
      <th scope="col">Chapter </th>
      <th scope="col">Class </th>
      <th scope="col">Reason </th>
      <th scope="col">View</th>
      <th scope="col text-center">Status </th>
      <th scope="col text-center">Action </th>

    </tr>
  </thead>
  {Data?.map((item,index)=>
  <tbody key={index} >
  <tr >
     <th scope="row">{index+1}</th>
     <td className='text-secondary'>{item.studentName}</td>
     <td className='text-secondary'>{item.subjectName}</td>
     <td className='text-secondary'>{item.chapterName}</td>
     <td className='text-secondary'>{item.standard}</td>
     <td className='text-secondary'>{item.reason}</td>
     <td> <IoEyeSharp/></td>
     <td><span className="badge badge-success">Pending</span></td>
     <td><button type="button" className="btn btn-outline-success m-1">Approve </button>
<button type="button" className="btn btn-outline-danger ">Reject</button></td>
     
   </tr>
  
 </tbody>
)}
  
</table>
</div>
   
    </div>
  


    
    
    </div>
  
  )
}
