import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Badge } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLectureReq } from "../../redux/actions/requestActions";
import { setTeacherDetails, teacher_logout } from "../../redux/actions/teacherActions";
import logo from "../../images/ec_logo_nobg.png";


function TeacherNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { requests } = useSelector((store) => store.requestReducer);
  const { isAuthenticated, teacherData } = useSelector(
    (store) => store.teacherReducer
  );

  const teacherId = teacherData?.id;

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElExams, setAnchorElExams] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenExamsMenu = (event) => {
    setAnchorElExams(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseExamsMenu = () => {
    setAnchorElExams(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleCloseNavMenu();
  };

  const handleLogout = () => {
    dispatch(teacher_logout(navigate));
  };

  useEffect(() => {
    dispatch(getLectureReq(teacherId)); 
    dispatch(setTeacherDetails())// Dispatch action to fetch lecture requests when component mounts
  }, [dispatch, teacherId]);

  const numberOfRequests = requests?.user?.length;

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#383838" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar>
          <Box
            component="img"
            sx={{
              display: { xs: "none", md: "flex" },
              maxHeight: { xs: 65, md: 65 },
              maxWidth: { xs: 65, md: 65 },
              mx: 5,
              cursor: "pointer",
            }}
            alt="The company logo"
            src={logo}
            onClick={() => handleNavigate("/teacher-home")}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={() => handleNavigate("/teacher-home")}>
                <Typography textAlign="center">HOME</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/teacher-home")}>
                <Typography textAlign="center">LIVE CLASS</Typography>
              </MenuItem>
              <MenuItem onClick={handleOpenExamsMenu}>
                <Typography textAlign="center">
                  EXAMS
                  <ChevronRightIcon />
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/live-requests")}>
                <Typography textAlign="center">
                  REQUESTS
                  <Badge
                    color="secondary"
                    sx={{ px: 0.8 }}
                    badgeContent={numberOfRequests}
                    max={5}
                  />
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/teacher-projects")}>
                <Typography textAlign="center">PROJECTS</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/teacher-users")}>
                <Typography textAlign="center">USERS</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/teacher-home")}
            >
              Home
            </Button>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/teacher-home")}
            >
              Live Class
            </Button>
            <Button
              onClick={handleOpenExamsMenu}
              sx={{ my: 2, color: "white", display: "flex", alignItems: "center" }}
            >
              Exams <ExpandMoreIcon />
            </Button>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/live-requests")}
            >
              Requests
              <Badge
                color="secondary"
                sx={{ px: 0.8 }}
                badgeContent={numberOfRequests}
                max={10}
              />
            </Button>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/teacher-projects")}
            >
              Projects
            </Button>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/teacher-users")}
            >
              Users
            </Button>
          </Box>

          <Menu
            id="menu-exams"
            anchorEl={anchorElExams}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElExams)}
            onClose={handleCloseExamsMenu}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#333",
                borderRadius: "8px",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.3s ease, opacity 0.3s ease",
                transformOrigin: "top left",
              },
              "& .MuiMenuItem-root": {
                color: "#fff",
                padding: "12px 20px",
                transition: "background-color 0.2s ease, color 0.2s ease, transform 0.2s ease",
                "&:hover": {
                  backgroundColor: "#444",
                  color: "#edb038",
                  transform: "scale(1.05)",
                },
              },
            }}
          >
            <MenuItem onClick={() => handleNavigate("/test/mcq-test")}>
              MCQ Exam
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/test/subjective-test")}>
              Subjective Exam
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/test/mega-test")}>
              Mega Exam
            </MenuItem>
          </Menu>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>CP</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{
                mt: "45px",
                "& .MuiPaper-root": {
                  backgroundColor: "#333",
                  borderRadius: "8px",
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                },
                "& .MuiMenuItem-root": {
                  color: "#fff",
                  padding: "12px 20px",
                  transition: "background-color 0.2s ease, color 0.2s ease, transform 0.2s ease",
                  "&:hover": {
                    backgroundColor: "#444",
                    color: "#edb038",
                    transform: "scale(1.05)",
                  },
                },
              }}
            >
              {/* <MenuItem onClick={() => handleNavigate("/profile")}>
                Profile
              </MenuItem> */}
              <MenuItem onClick={() => handleNavigate("/setting")}>
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default TeacherNavbar;

