import React, { useState, useEffect } from "react";
import "../styles/LivePlans.css";
import { Form } from "react-bootstrap";
import Box from "@mui/joy/Box";
import PlanCard from "./PlanCard";
import { useSelector } from "react-redux";
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import RewardSelection from "./RewardSelection";

export default function LivePlans({ closeModal }) {
  const { userData } = useSelector((store) => store.userReducer); 
  const { lecturePrices } = useSelector((store) => store.lectureReducer); 
  const { allCourses } = useSelector((store) => store.courseReducer); 
  const { allBoards } = useSelector((store) => store.boardReducer);
  
  const [selectedSubjects, setSelectedSubjects] = useState([]); 
  const [selectedBoard, setSelectedBoard] = useState('CBSE'); // default board
  const [showRewardSelection, setShowRewardSelection] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [pointsUsed, setPointsUsed] = useState({ monthly: 0, quarterly: 0, yearly: 0 });
  const [finalPrice, setFinalPrice] = useState({ monthly: 0, quarterly: 0, yearly: 0 });

  useEffect(() => {
    const defaultSubject = getFilteredCourses(userData?.standard, selectedBoard)?.[0];
    if (defaultSubject) setSelectedSubjects([{ id: defaultSubject.id, subject: defaultSubject.subject }]);
  }, [userData, allCourses, selectedBoard]);

  const getFilteredCourses = (standard, board) => {
    return allCourses?.filter(
      (course) => course?.class === standard.toString() && course?.board === board
    );
  };

  const handleSubjectSelection = (subject) => {
    setSelectedSubjects((prevSubjects) =>
      prevSubjects.some(s => s.subject === subject.subject) 
        ? prevSubjects.filter(s => s.subject !== subject.subject)
        : [...prevSubjects, { id: subject.id, subject: subject.subject }]
    );
  };

  const removeReward = () => {
    updatePrices();
    setSelectedReward(null);
    setPointsUsed({ monthly: 0, quarterly: 0, yearly: 0 });
  };

  const updatePrices = () => {
    const packagePrices = lecturePrices.find(
      (lecture) => lecture.packageName === getPackageBySubjects()
    );
    if (packagePrices) {
      setFinalPrice({
        monthly: packagePrices.monthly_prices,
        quarterly: packagePrices.quarterly_prices,
        yearly: packagePrices.yearly_prices,
      });
      setSelectedReward(null);
      setPointsUsed({ monthly: 0, quarterly: 0, yearly: 0 });
    }
  };

  const getPackageBySubjects = () => {
    const subjectCount = selectedSubjects.length;
    if (subjectCount === 1) return "single";
    if (subjectCount === 2) return "double";
    if (subjectCount === 3) return "triple";
    if (subjectCount >= 4) return "premium";
  };

  useEffect(() => {
    updatePrices();
  }, [selectedSubjects, lecturePrices]);

  return (
    <div className="mx-5 mt-4">
      <div className="row justify-content-end justify-items-center">
        <button type="btn-2" className="close" onClick={closeModal} aria-label="Close"> X </button>
      </div>

    
      {showRewardSelection ? (
        <RewardSelection
          userData={userData}
          closeModal={() => setShowRewardSelection(false)}
          setPointsUsed={setPointsUsed}
          setSelectedReward={setSelectedReward}
          setFinalPrice={setFinalPrice}
          finalPrice={finalPrice}
          setShowRewardSelection={setShowRewardSelection}
          getPackageBySubjects={getPackageBySubjects}
          lecturePrices={lecturePrices}
        />
      ) : (
        <>
          <div className="d-flex justify-content-between">

            <div className="d-flex">
            <DropdownButton id="dropdown-basic-button" title="Select Subjects">
              {getFilteredCourses(userData?.standard, selectedBoard).map((course, index) => (
                <Dropdown.Item 
                  key={index} 
                  as="button" 
                  onClick={() => handleSubjectSelection(course)}
                >
                  <Form.Check 
                    type="checkbox" 
                    label={course.subject} 
                    checked={selectedSubjects.some(s => s.subject === course.subject)} 
                    onChange={() => handleSubjectSelection(course)} 
                  />
                </Dropdown.Item>
              ))}
            </DropdownButton>


            <DropdownButton id="dropdown-basic-button" title="Select Board">
        {allBoards.map((board, index) => (
          <Dropdown.Item key={index} as="button" onClick={() => setSelectedBoard(board.name)}>
            {board.name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      </div>


            {!selectedReward ? (
              <div>
                <button onClick={() => setShowRewardSelection(true)}>Use Points</button>
              </div>
            ) : (
              <div>
                <p>
                  <strong>
                    {selectedReward === 'welcomeBonus' ? 'Welcome Bonus' : 'Loyalty Points'} applied!
                  </strong>
                  <button onClick={removeReward}>Remove</button>
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <strong>Selected Subjects: </strong>
            {selectedSubjects.map(s => s.subject).join(', ') || "None"}
          </div>

          <div className="mb-5">
            <Box sx={{ width: '100%', display: 'flex', gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))', gap: 2 }}>
              <PlanCard 
                title="Monthly" 
                price={finalPrice.monthly} 
                originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.monthly_prices} 
                features={["Recorded Lectures", "Live Sessions", "API Integration"]} 
                pointsUsed={pointsUsed} 
                selectedReward={selectedReward} 
                selectedSubjects={selectedSubjects.map(s => s.id)}
                packageName={getPackageBySubjects()}
              />
              <PlanCard 
                title="Quarterly" 
                price={finalPrice.quarterly} 
                originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.quarterly_prices} 
                features={["Virtual Credit Cards", "Financial Analytics", "API Integration"]} 
                pointsUsed={pointsUsed} 
                selectedReward={selectedReward} 
                selectedSubjects={selectedSubjects.map(s => s.id)}
                packageName={getPackageBySubjects()}
              />
              <PlanCard 
                title="Yearly" 
                price={finalPrice.yearly} 
                originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.yearly_prices} 
                features={["Unlimited Access", "Live Classes", "Recorded Lectures", "Cancel Anytime"]} 
                pointsUsed={pointsUsed} 
                selectedReward={selectedReward} 
                selectedSubjects={selectedSubjects.map(s => s.id)}
                isPopular 
                packageName={getPackageBySubjects()}
              />
            </Box>
          </div>
        </>
      )}
    </div>
  );
}








// import React, { useState, useEffect } from "react";
// import "../styles/LivePlans.css";
// import { Form } from "react-bootstrap";
// import Box from "@mui/joy/Box";
// import { useSelector } from "react-redux";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import RewardSelection from "./RewardSelection";
// import PlanCard from "./PlanCard";



// export default function LivePlans({ closeModal }) {
//   const { userData } = useSelector((store) => store.userReducer); 
//   const { lecturePrices } = useSelector((store) => store.lectureReducer); 
//   const { allCourses } = useSelector((store) => store.courseReducer); 
//   const { allBoards } = useSelector((store) => store.boardReducer);
  
//   const [selectedSubjects, setSelectedSubjects] = useState([]); 
//   const [showRewardSelection, setShowRewardSelection] = useState(false);
//   const [selectedReward, setSelectedReward] = useState(null);
//   const [pointsUsed, setPointsUsed] = useState({ monthly: 0, quarterly: 0, yearly: 0 });
//   const [finalPrice, setFinalPrice] = useState({ monthly: 0, quarterly: 0, yearly: 0 });

//   useEffect(() => {
//     const defaultSubject = getFilteredCourses(userData?.standard, 'CBSE')?.[0];
//     if (defaultSubject) setSelectedSubjects([{ id: defaultSubject.id, subject: defaultSubject.subject }]);
//   }, [userData, allCourses]);

//   const getFilteredCourses = (standard, board) => {
//     return allCourses?.filter(
//       (course) => course?.class === standard.toString() && course?.board === board
//     );
//   };

//   const filteredCourses = getFilteredCourses(userData?.standard, 'CBSE');

//   const handleSubjectSelection = (subject) => {
//     setSelectedSubjects((prevSubjects) =>
//       prevSubjects.some(s => s.subject === subject.subject) 
//         ? prevSubjects.filter(s => s.subject !== subject.subject)
//         : [...prevSubjects, { id: subject.id, subject: subject.subject }]
//     );
//   };

//   const removeReward = () => {
//     updatePrices();
//     setSelectedReward(null);
//     setPointsUsed({ monthly: 0, quarterly: 0, yearly: 0 });
//   };

//   const updatePrices = () => {
//     const packagePrices = lecturePrices.find(
//       (lecture) => lecture.packageName === getPackageBySubjects()
//     );
//     if (packagePrices) {
//       setFinalPrice({
//         monthly: packagePrices.monthly_prices,
//         quarterly: packagePrices.quarterly_prices,
//         yearly: packagePrices.yearly_prices,
//       });
//       setSelectedReward(null);
//       setPointsUsed({ monthly: 0, quarterly: 0, yearly: 0 });
//     }
//   };

//   const getPackageBySubjects = () => {
//     const subjectCount = selectedSubjects.length;
//     if (subjectCount === 1) return "single";
//     if (subjectCount === 2) return "double";
//     if (subjectCount === 3) return "triple";
//     if (subjectCount >= 4) return "premium";
//   };

//   useEffect(() => {
//     updatePrices();
//   }, [selectedSubjects, lecturePrices]);

//   console.log('selectedSubjects', selectedSubjects)

//   return (
//     <div className="mx-5 mt-4">
//       <div className="row justify-content-end justify-items-center">
//         <button type="btn-2" className="close" onClick={closeModal} aria-label="Close"> X </button>
//       </div>

//       {showRewardSelection ? (
//         <RewardSelection
//           userData={userData}
//           closeModal={() => setShowRewardSelection(false)}
//           setPointsUsed={setPointsUsed}
//           setSelectedReward={setSelectedReward}
//           setFinalPrice={setFinalPrice}
//           finalPrice={finalPrice}
//           setShowRewardSelection={setShowRewardSelection}
//           getPackageBySubjects={getPackageBySubjects}
//           lecturePrices={lecturePrices}
//         />) : (
//         <>
//           <div className="d-flex justify-content-between">
//             <DropdownButton id="dropdown-basic-button" title="Select Subjects">
//               {filteredCourses.map((course, index) => (
//                 <Dropdown.Item 
//                   key={index} 
//                   as="button" 
//                   onClick={() => handleSubjectSelection(course)}
//                 >
//                   <Form.Check 
//                     type="checkbox" 
//                     label={course.subject} 
//                     checked={selectedSubjects.some(s => s.subject === course.subject)} 
//                     onChange={() => handleSubjectSelection(course)} 
//                   />
//                 </Dropdown.Item>
//               ))}
//             </DropdownButton>

//             {!selectedReward ? (
//               <div>
//                 <button onClick={() => setShowRewardSelection(true)}>Use Points</button>
//               </div>
//             ) : (
//               <div>
//                 <p>
//                   <strong>
//                     {selectedReward === 'welcomeBonus' ? 'Welcome Bonus' : 'Loyalty Points'} applied!
//                   </strong>
//                   <button onClick={removeReward}>Remove</button>
//                 </p>
//               </div>
//             )}
//           </div>

//           <div className="mb-4">
//             <strong>Selected Subjects: </strong>

//             {selectedSubjects.map(s => s.subject).join(', ') || "None"}
//           </div>

//           <div className="mb-5">
//             <Box sx={{ width: '100%', display: 'flex', gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))', gap: 2 }}>
//               <PlanCard 
//                 title="Monthly" 
//                 price={finalPrice.monthly} 
//                 originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.monthly_prices} 
//                 features={["Recorded Lectures", "Live Sessions", "API Integration"]} 
//                 pointsUsed={pointsUsed} 
//                 selectedReward={selectedReward} 
//                 selectedSubjects={selectedSubjects.map(s => s.id)}
//                 packageName={getPackageBySubjects()}
           
//               />
//               <PlanCard 
//                 title="Quarterly" 
//                 price={finalPrice.quarterly} 
//                 originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.quarterly_prices} 
//                 features={["Virtual Credit Cards", "Financial Analytics", "API Integration"]} 
//                 pointsUsed={pointsUsed} 
//                 selectedReward={selectedReward} 
//                 selectedSubjects={selectedSubjects.map(s => s.id)}
//                 packageName={getPackageBySubjects()}

//               />
//               <PlanCard 
//                 title="Yearly" 
//                 price={finalPrice.yearly} 
//                 originalPrice={lecturePrices.find(lecture => lecture.packageName === getPackageBySubjects())?.yearly_prices} 
//                 features={["Unlimited Access", "Live Classes", "Recorded Lectures", "Cancel Anytime"]} 
//                 pointsUsed={pointsUsed} 
//                 selectedReward={selectedReward} 
//                 selectedSubjects={selectedSubjects.map(s => s.id)}
//                 isPopular 
//                packageName={getPackageBySubjects()}
//               />
//             </Box>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }
