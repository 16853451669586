import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllExams } from '../../../redux/actions/getStudentActions';
import { useNavigate } from 'react-router-dom';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function SubjectiveCard() {

      const dispatch=useDispatch()
      const navigate=useNavigate()
const {allExams,selectedStudent}=useSelector((store)=>store.studentReducer)


useEffect(()=>{
  dispatch(fetchAllExams())
    },[])

  const filteredSubjectWiseExams=allExams?.filter((item)=>  item.select_class===selectedStudent?.class?.toString() && item.testType==="subject_wise")

  
  
  return (
    <Card sx={{ maxwidth: 350}} className='mb-5'>
      <CardContent>
      <Typography sx={{ fontSize: 15 ,fontWeight:600,color:'#383838'}}   gutterBottom>
      Total Subjective Exams 
        </Typography>
        <Typography variant="h2" sx={{fontWeight:500, background: 'linear-gradient(to right, #fb794a, #edb038)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',}} component="div">
         {filteredSubjectWiseExams?.length}
        </Typography>
        <Typography  color="#383838">
          Overall Attendance : 25 %< br/>
          
        </Typography>
     
      </CardContent>
      <CardActions >
        <Button size="small"  onClick={()=>navigate("/parents/subjective")}>VIEW</Button>
      </CardActions>
    </Card>
  );
}