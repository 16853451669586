import { GET_ALL_USERS_BY_TEACHER,CREATE_SINGLE_USER_BY_TEACHER,CREATE_BULK_USER,ALL_PENDING_USERS} from '../actionsType';
import * as api from './api';



export const getAllPendingUsers=()=>async(dispatch)=>{
    try{
        const {data}=await api.getAllPendingUsers();
        dispatch({
            type:ALL_PENDING_USERS,
            payload:data
        })

        return data
    }
    catch(err){
        throw err;
    }

}

export const getAllUsersByTeacher=()=>async(dispatch)=>{
    try{
        const {data}=await api.getAllUsersByTeacher();
        dispatch({
            type:GET_ALL_USERS_BY_TEACHER,
            payload:data
        })

        return data
    }
    catch(err){
        throw err;
    }

}


export const createSingleUserByTeacher=(formData)=>async(dispatch)=>{
    try {
        const {data}=await api.createSingleUserByTeacher(formData);
        console.log('data from ', data)
        dispatch({
            type:CREATE_SINGLE_USER_BY_TEACHER,
            payload:data
        })
    } catch (error) {
        throw error;
    }

}


export const createBulkUser=(formData)=>async(dispatch)=>{
    try {
        const {data}=await api.createBulkUser(formData);
        dispatch({
            type:CREATE_BULK_USER,
            payload:data
        })
    } catch (error) {
        throw error;
    }

}